import styled from 'styled-components';
import {sizes, colors} from '../../../styles/variables';
import {gradientCandyCarrot, media, orangeBorder, withUnit} from '../../../styles/utils';

export const VideoWrapperStyled = styled.div`
	padding-bottom: ${withUnit(sizes.distance.base * 2)};
	${media.xl`
		display: flex;
		align-items: center;
	`};
`;

export const CaptionStyled = styled.div`
	padding-bottom: ${withUnit(sizes.distance.base)};

	div {
		position: relative;
		font-size: 0.8em;
		padding-bottom: ${withUnit(sizes.distance.base)};
		${orangeBorder()};

		p {
			margin: 0;
		}
	}

	${media.xl`
		padding: ${withUnit(sizes.distance.base)};
		width: 40%;
	`};
`;

export const VideoStyled = styled.div`
	padding-bottom: ${withUnit(sizes.distance.base)};

	${media.xl`
		padding: 0;
		width: 60%;
	`};

	.brightcove-react-player-loader {
		width: 100%;
		height: auto;

		.vjs-big-play-button {
			${gradientCandyCarrot()};
			font-size: ${withUnit(sizes.distance.base)};
		}
		.vjs-play-progress {
			background-color: ${colors.candy};
		}
	}
`;
