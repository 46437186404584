import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {TabsStyled, TabList, Tab, Gradient, TabContent, TabsWrapper, ScrollWrapper} from './styles';

const Tabs = ({items}) => {
	const [tabIndex, setTabIndex] = useState(0);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const tabId = params.get('tabId');
		const index = items.findIndex((item) => item.tabid === tabId);
		if (index !== -1) setTabIndex(index);
		setTimeout(() => {
			const anchorId = params.get('anchorId');
			const element = document.getElementById(anchorId);
			if (element) {
				element.scrollIntoView({behavior: 'smooth'});
			}
		}, 500);
	}, []);

	const tabWidth = 100 / items.length;

	const variants = {
		anim0: {
			left: '0',
		},
		anim1: {
			left: `${tabWidth}%`,
		},
		anim2: {
			left: `${2 * tabWidth}%`,
		},
		anim3: {
			left: `${3 * tabWidth}%`,
		},
	};

	const changeTab = (index, scrollTop) => {
		setTabIndex(index);
		const tabName = items[index].tabname;

		const url = new URL(window.location.href);
		url.searchParams.set('tabId', tabName);
		window.history.replaceState(null, null, url.toString());

		if (scrollTop) {
			setTimeout(() => {
				window.scrollTo({top: 0, behavior: 'smooth'});
			}, 500);
		}
	};

	return (
		<TabsStyled>
			<TabList>
				<ScrollWrapper>
					<TabsWrapper>
						<Gradient
							initial="anim0"
							animate={`anim${tabIndex}`}
							transition={{type: 'spring', velocity: 1}}
							variants={variants}
							width={`${tabWidth}%`}
						/>

						{items.map(({tabname}, index) => (
							<Tab
								onClick={() => changeTab(index, false)}
								active={tabIndex === index}
								key={tabname}
								width={`${tabWidth}%`}>
								{tabname}
							</Tab>
						))}
					</TabsWrapper>
				</ScrollWrapper>
			</TabList>

			<TabContent>{items[tabIndex].content}</TabContent>

			<TabList>
				<ScrollWrapper>
					<TabsWrapper>
						<Gradient
							initial="anim0"
							animate={`anim${tabIndex}`}
							transition={{type: 'spring', velocity: 1}}
							variants={variants}
							width={`${tabWidth}%`}
						/>

						{items.map(({tabname}, index) => (
							<Tab
								onClick={() => changeTab(index, true)}
								active={tabIndex === index}
								key={tabname}
								width={`${tabWidth}%`}>
								{tabname}
							</Tab>
						))}
					</TabsWrapper>
				</ScrollWrapper>
			</TabList>
		</TabsStyled>
	);
};

Tabs.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape(PropTypes.string, PropTypes.any)).isRequired,
};

Tabs.defaultProps = {};

/** @component */
export default Tabs;
