import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Container} from './styles';

const Spotify = (props) => {
	const {content, className} = props;
	const [width, setWidth] = useState(0);

	useEffect(() => setWidth(document.getElementsByClassName(className)[0].offsetWidth));

	return (
		<Container className={className}>
			<iframe
				src={content}
				width={width}
				height="380"
				frameBorder="0"
				title="spotify-player"
				allowtransparency="true"
				allow="encrypted-media"
			/>
		</Container>
	);
};

Spotify.propTypes = {
	className: PropTypes.string,
	content: PropTypes.string.isRequired,
};

Spotify.defaultProps = {
	className: 'ap-quote',
};

/** @component */
export default Spotify;
