import React from 'react';
import PropTypes from 'prop-types';
import WpColumn1 from './WpColumn1';
import WpColumn2 from './WpColumn2';
import Row from '../../Layout/Grid/Row';
import {reactComponents} from './Components';

const WpRow21 = (props) => {
	const {
		content: {elements},
	} = props;
	const renderComponentsCol1 = [];
	const renderComponentsCol2 = [];
	const styles =
		elements.length > 0
			? {
					alignItems: elements[0].alignItems,
			  }
			: null;

	elements.map((elem, i) => {
		const Component = reactComponents[elem.__typename];
		const c = <Component key={i} content={elem} />;
		if (elem.column === 'column_1') {
			renderComponentsCol1.push(c);
		} else if (elem.column === 'column_2') {
			renderComponentsCol2.push(c);
		}
		return null;
	});

	return (
		<Row styles={styles}>
			<WpColumn2>{renderComponentsCol2}</WpColumn2>
			<WpColumn1>{renderComponentsCol1}</WpColumn1>
		</Row>
	);
};

WpRow21.propTypes = {
	content: PropTypes.shape({
		elements: PropTypes.arrayOf.isRequired,
	}),
};

WpRow21.defaultProps = {
	content: {
		elements: [],
	},
};

/** @component */
export default WpRow21;
