import React from 'react';
import {StaticQuery, graphql} from 'gatsby';

import Row from '../../Layout/Grid/Row';

import {LinkStyled, IconBox, IconStyled, BGImageStyled, TextStyled, Wrapper, ColumnStyled} from './styles';

const DossierIconBox = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					imageOne: file(relativePath: {eq: "img_teaser-news.png"}) {
						childImageSharp {
							original {
								src
							}
							fluid(srcSetBreakpoints: [800, 250], quality: 80) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			`}
			render={(data) => {
				return (
					<>
						<Row>
							<ColumnStyled>
								<LinkStyled to="/multiple-sklerose/news-specials">
									<Wrapper>
										<BGImageStyled src={data.imageOne.childImageSharp.original.src} />

										<IconBox>
											<IconStyled name="megaphone" type="teaser" style={{zIndex: '9999'}} />
											<TextStyled content="News & Specials" />
										</IconBox>
									</Wrapper>
								</LinkStyled>
							</ColumnStyled>
						</Row>
					</>
				);
			}}
		/>
	);
};

export default DossierIconBox;
