import styled from 'styled-components';
import {media, gradientCandyCarrot} from '../../../styles/utils';
import {colors, fonts, sizes} from '../../../styles/variables';
import Link from '../../Elements/Link/Link';
import Section from '../../Layout/Section/Section';
import {Icon} from '../../Elements/Icon/Icon';
import {withUnit} from '../../../styles/utils';

export const SectionStyled = styled(Section)`
	width: 100%;
`;

export const LogoContainer = styled.div`
	margin-bottom: 1.5em;
`;

export const LeftBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	width: 100%;
`;
export const RightBlock = styled.div`
	min-width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	${media.xl`
		margin-left: 3em;
	`}
`;

export const Container = styled.div`
	min-height: 15em;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: ${(props) => (props.center ? 'center' : 'flex-start')};
	background: ${colors.almostwhite};
	padding: 1em;
`;

export const LinkStyled = styled(Link)`
	display: flex;
	align-items: center;
	margin-bottom: 1em;
	padding: 0;
	text-align: left;
`;

export const TextBlockStyled = styled.div`
	display: block;
	z-index: 10;
`;

export const StyledText = styled.p`
	font-weight: ${(props) => (props.bold ? 'bold' : 'regular')};
	font-size: ${(props) => (props.small ? '0.8em' : '1em')};
	font-size: ${(props) => (props.large ? '1.2em' : '1em')};
	margin: ${(props) => (props.flat ? '0' : '')};
	padding-left: ${(props) => (props.flat ? '0.5rem' : '')};
`;

export const Bold = styled.span`
	font-weight: bold;
`;

export const IconStyled = styled.div`
	background: ${gradientCandyCarrot()};
	border-radius: 50%;
	padding: 0.5rem;
	display: flex;
	width: 1em;
	height: 1em;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	color: white;
`;

export const FlexBlock = styled.div`
	display: flex;
	align-items: center;
`;

export const InlineBlock = styled.div`
	margin-left: 2.5em;
`;

export const InfoIconStyled = styled(Icon)`
	z-index: 2;
	position: absolute;
	padding: 0 ${withUnit(sizes.distance.base / 3)};
	transform: rotate(-7deg);

	width: 50vw;
    max-width 300px; 
	height: 50vw;
    max-height: 300px;
	bottom: 0vw;
	right: 0vw;

	svg {
		width: 50vw;
    max-width 300px; 
	height: 50vw;
    max-height: 300px;
		fill: ${colors.lightgrey};
		opacity: 0.5;

		
	}
`;
