import React from 'react';
import Row from '../../Layout/Grid/Row';
import Column from '../../Layout/Grid/Column';
import Headline from '../../Elements/Headline/Headline';
import BrochureSelectionItem from './BrochureSelectionItem';
import {brochureLists} from './domainModels';

const BrochureSelection = formikProps => (
	<Row>
		<Column md={6}>
			<Headline as="h3" text="Broschüren füry MS-Patienten" />
			{brochureLists.forMsPatients.map(brochureData => (
				<BrochureSelectionItem {...brochureData} />
			))}
		</Column>
		<Column md={6}>
			<Headline as="h3" text="Broschüren für Angehörige" />
			{brochureLists.forFamilyMembers.map(brochureData => (
				<BrochureSelectionItem {...brochureData} />
			))}
		</Column>
	</Row>
);

export default BrochureSelection;
