import React from 'react';
import PropTypes from 'prop-types';
import Row from '../../Layout/Grid/Row';
import WpColumn from './WpColumn';

import {reactComponents} from './Components';

const WpRow = (props) => {
	const {
		content: {elements},
	} = props;
	const renderComponents = elements.map((elem, i) => {
		const Component = reactComponents[elem.__typename];
		// console.log('elem.__typename: ', elem.__typename);
		// console.log('Component: ', Component);
		if (!Component) {
			return null;
		}
		return <Component key={i} content={elem} />;
	});

	return (
		<Row>
			<WpColumn>{renderComponents}</WpColumn>
		</Row>
	);
};

WpRow.propTypes = {
	content: PropTypes.shape({
		elements: PropTypes.arrayOf.isRequired,
	}),
};

WpRow.defaultProps = {
	content: {
		elements: [],
	},
};

/** @component */
export default WpRow;
