import React from 'react';
import PropTypes from 'prop-types';
import {
	TeaserStyled,
	ServiceTeaserImageStyled,
	ServiceTeaserTitleStyled,
	ServiceTeaserContentStyled,
	ServiceTeaserSubtitleStyled,
	ServiceTeaserTextStyled,
	appearances,
	TeaserCard,
} from './styles';

import Placeholder from '../../../../assets/img/img_cleo.jpg';

const Teaser = (props) => {
	const {item, position} = props;
	const imageProps = {type: 'teaser', appearance: item.appearance};
	const text = item.text ? <ServiceTeaserTextStyled dangerouslySetInnerHTML={{__html: item.text}} /> : null;
	return (
		<TeaserCard>
			<TeaserStyled
				data-teaser-type="ContentTeaser"
				data-teaser-title={item.title}
				data-teaser-link={item.link?.url}
				data-teaser-position={position}
				href={item.link?.url}
				className="ap-teaserbox"
				{...props}>
				<ServiceTeaserImageStyled
					src={item.image.localFile.publicURL || Placeholder}
					alt={item.image.alt_text}
					title={item.image.title}
					{...imageProps}
				/>
				<ServiceTeaserContentStyled>
					<ServiceTeaserTitleStyled dangerouslySetInnerHTML={{__html: item.title}} />
					{item.subtitle ? (
						<ServiceTeaserSubtitleStyled dangerouslySetInnerHTML={{__html: item.subtitle}} />
					) : null}
					{text}
				</ServiceTeaserContentStyled>
			</TeaserStyled>
		</TeaserCard>
	);
};

Teaser.propTypes = {
	position: PropTypes.number,
	item: PropTypes.shape({
		appearance: PropTypes.oneOf(appearances),
		label: PropTypes.string,
		title: PropTypes.string,
		subtitle: PropTypes.string,
		text: PropTypes.string,
		onClick: PropTypes.func,
		link: PropTypes.shape({
			title: PropTypes.string,
			url: PropTypes.string,
			target: PropTypes.string,
		}),
		image: PropTypes.shape({
			source_url: PropTypes.string,
			alt_text: PropTypes.string,
			title: PropTypes.string,
			localFile: PropTypes.shape({
				publicURL: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.shape({
						srcSet: PropTypes.string,
						src: PropTypes.string,
					}),
				]),
			}),
		}),
	}),
};

Teaser.defaultProps = {
	position: 0,
	item: {
		appearance: 'default',
		label: '',
		title: '',
		subtitle: '',
		text: '',
		onClick: null,
		link: null,
		image: null,
	},
};

/** @component */
export default Teaser;
