import React from 'react';
import PropTypes from 'prop-types';
import ServiceTeaserBox from '../../Elements/Teaser/ServiceTeaserBox/ServiceTeaserBox';
import Row from '../../Layout/Grid/Row';

const WpServiceTeaser = ({content}) => {
	const {items} = content;

	return (
		<Row styles={{marginBottom: true, padding: '0 0 2rem 0', justifyContent: 'center'}}>
			{items.map((item, index) => {
				return (
					<ServiceTeaserBox
						key={item.key}
						position={index + 1}
						item={{
							appearance: 'shadow',
							title: item.title,
							subtitle: item.description,
							image: {
								localFile: {
									publicURL:
										item.image && item.image.localFile
											? item.image.localFile.childImageSharp.fluid
											: undefined,
								},
							},
							link: item.link,
						}}
					/>
				);
			})}
		</Row>
	);
};

WpServiceTeaser.propTypes = {
	content: PropTypes.shape({
		items: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string,
				title: PropTypes.string,
				description: PropTypes.string,
				image: PropTypes.shape({
					alt_text: PropTypes.string,
					title: PropTypes.string,
					localFile: PropTypes.shape({
						childImageSharp: PropTypes.shape({
							original: PropTypes.shape({
								src: PropTypes.string.isRequired,
							}),
						}),
					}),
				}),
			})
		),
	}),
};

WpServiceTeaser.defaultProps = {
	content: {
		items: null,
	},
};

/** @component */
export default WpServiceTeaser;
