/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';

import {
	ProductContainerStyled,
	ProductTitle,
	DownloadStyled,
	BackgroundIcon,
	ProductInfo,
	ProductInfoContainerStyled
} from '../styles';
import {Icon} from '../../../Elements/Icon/Icon';
import IconButton from '../../../Elements/IconButton/IconButton';

const Product = ({name, strength, vector, prescription, substance, usage_info, icon}) => (
	<ProductContainerStyled>
		{icon && (
			<BackgroundIcon>
				<Icon name={icon} height="23em" width="23em" type="teaser" />
			</BackgroundIcon>
		)}
		<ProductInfo>
			<ProductInfoContainerStyled>
				<ProductTitle>{name}</ProductTitle>
				{strength && (
					<p>
						<strong>Verfügbare Stärke:</strong> {strength}
					</p>
				)}
				<p>
					<strong>Verschreibungspflichtig:</strong> {prescription ? 'ja' : 'nein'}
				</p>
				{substance && (
					<p>
						<strong>Wirkstoff:</strong> {substance}
					</p>
				)}
				{vector && (
					<p>
						<strong>Darreichung:</strong> {vector}
					</p>
				)}
			</ProductInfoContainerStyled>
			{!!usage_info &&
				usage_info.map(
					el =>
						!!el.link.url && (
							<DownloadStyled to={el.link.url} key={el.link.url}>
								<IconButton text={el.link.title}>
									<Icon name="download" />
								</IconButton>
							</DownloadStyled>
						)
				)}
		</ProductInfo>
	</ProductContainerStyled>
);

Product.propTypes = {
	name: PropTypes.string.isRequired,
	strength: PropTypes.string.isRequired,
	vector: PropTypes.string,
	prescription: PropTypes.bool,
	substance: PropTypes.string.isRequired,
	usage_info: PropTypes.arrayOf(PropTypes.object),
	icon: PropTypes.string
};

Product.defaultProps = {
	usage_info: null,
	prescription: true,
	vector: null,
	icon: null
};

export default Product;
