import styled from 'styled-components';
import {GlobalFormStyle} from '../../../styles/global';

export const CallbackFormStyled = styled.div`
	${GlobalFormStyle};
`;

export const ParagraphWithMargin = styled.p`
	margin-bottom: 2em;
`;
