import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

const WpCookiesPage = ({content}) => {
	if (!content.render) {
		return null;
	}

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://consent.cookiebot.com/63c3fb9e-3fd9-4074-a554-eb1070c6bf55/cd.js';
		script.async = true;
		script.type = 'text/javascript';
		document.getElementById('cookie-declaration').appendChild(script);
	}, []);

	return <div id="cookie-declaration" />;
};

WpCookiesPage.propTypes = {
	content: PropTypes.shape({
		render: PropTypes.bool,
	}).isRequired,
};

WpCookiesPage.defaultProps = {};

/** @component */
export default WpCookiesPage;
