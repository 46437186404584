import React from 'react';
import Column from '../../Layout/Grid/Column';
import Headline from '../../Elements/Headline/Headline';
import Row from '../../Layout/Grid/Row';
import Button from '../../Elements/Button/Button';
import {Input} from '../../Elements/Form/InputAndTextarea/InputAndTextarea';
import FormLabel from '../../Elements/Form/FormLabel/FormLabel';
import Checkbox from '../../Elements/Form/Checkbox/Checkbox';
import Captcha from '../../Elements/Form/Captcha/Captcha';
import Dropdown from '../../Elements/Form/Dropdown/Dropdown';

import {countries, federalStates, salutations} from './domainModels';
import {RightAlignedParagraph} from './styles';
import {forms} from '../../../assets/language/de';
import {withAsterisk} from '../../../utils/formHelpers';

const {label} = forms.BrochureOrder;

/**
 * @component
 *
 * we're inside a Formik/Form, this.props === formikProps of the outer render function
 *
 */
class BrochureOrderPersonalData extends React.PureComponent {
	render() {
		const formikProps = this.props;
		return (
			<React.Fragment>
				<Headline as="h3" text="Geben Sie bitte Ihre Daten ein:" />
				<div>
					<Row>
						<Column md={6}>
							<Dropdown label={withAsterisk(label.salutation)} items={salutations} name="salutation" />
						</Column>
					</Row>
					<Row>
						<Column md={6}>
							<Input label={withAsterisk(label.forename)} name="forename" />
						</Column>
						<Column md={6}>
							<Input label={withAsterisk(label.surname)} name="surname" />
						</Column>
					</Row>{' '}
					<Row>
						<Column md={6}>
							<Input label={withAsterisk(label.street)} name="street" />
						</Column>
						<Column md={6}>
							<Input label={withAsterisk(label.houseNumber)} name="houseNumber" />
						</Column>
					</Row>
					<Row>
						<Column md={6}>
							<Dropdown
								label={withAsterisk(label.federalState)}
								name="federalState"
								items={federalStates}
							/>
						</Column>
						<Column md={6}>
							<Dropdown label={withAsterisk(label.country)} name="country" items={countries} />
						</Column>
					</Row>
					<Row>
						<Column md={6}>
							<Input label={withAsterisk(label.email)} name="email" />
						</Column>
						<Column md={6}>
							<Input label={withAsterisk(label.phone)} name="phone" />
						</Column>
					</Row>
					<RightAlignedParagraph>
						<FormLabel text="* Pflichtangaben" />
					</RightAlignedParagraph>
				</div>
				<div>
					<Row>
						<Column>
							<Checkbox label={withAsterisk(label.hint1Accepted)} name="hint1Accepted" />
						</Column>
					</Row>
					<Row>
						<Column>
							<Checkbox label={withAsterisk(label.hint2Accepted)} name="hint2Accepted" />
						</Column>
					</Row>
					<Row>
						<Column>
							<Captcha name="captcha" />
						</Column>
					</Row>
					<Row>
						<Column>
							<div style={{textAlign: 'right'}}>
								<Button disabled={formikProps.isSubmitting} text="Abschicken" type="submit" />
							</div>
						</Column>
					</Row>
				</div>
			</React.Fragment>
		);
	}
}

export default BrochureOrderPersonalData;
