import React from 'react';
import star from '../../../assets/img/cleo/Star_orange.svg';

import {Styled} from './styles';

export const CleoReview1 = () => (
	<>
		<Styled.Block>
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
		</Styled.Block>

		<Styled.InlineText>Großartiger Support für MS-Patienten</Styled.InlineText>
		<Styled.Text>„Ein wirklich tolles Angebot für MS-Patienten – gab es in dieser Form noch nicht.“</Styled.Text>
		<Styled.Spacer />
	</>
);
