import React from 'react';
import {EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton} from 'react-share';
import PropTypes from 'prop-types';
import {SocialIconStyled, ShareBlockStyled} from './styles';

const ShareSocialMedia = ({title, location}) => {
	return (
		<ShareBlockStyled>
			<p>Seite Freunden senden</p>

			<div>
				<FacebookShareButton url={`${location.href}`} quote={title}>
					<SocialIconStyled name="facebook" type="category" />
				</FacebookShareButton>
				<TwitterShareButton url={`${location.href}`} title={title}>
					<SocialIconStyled name="twitter" type="category" />
				</TwitterShareButton>

				<EmailShareButton url={`${location.href}`} subject={'Link zu ms-life Seite'} body={`${title} : `}>
					<SocialIconStyled name="email_icon" type="category" />
				</EmailShareButton>
				<WhatsappShareButton url={`${location.href}`} title={title}>
					<SocialIconStyled name="whatsapp" type="category" />
				</WhatsappShareButton>
			</div>
		</ShareBlockStyled>
	);
};

export default ShareSocialMedia;

ShareSocialMedia.propTypes = {
	title: PropTypes.string,
};

ShareSocialMedia.defaultProps = {
	title: undefined,
};
