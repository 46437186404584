import React from 'react';
import PropTypes from 'prop-types';
import Intro from '../../Elements/Intro/Intro';

const WpIntro = props => {
	const {className, content} = props;
	return <Intro className={className} content={content.content} />;
};

WpIntro.propTypes = {
	/** Needed to overwrite styling via styled components. */
	className: PropTypes.string,
	content: PropTypes.shape({
		content: PropTypes.string.isRequired
	}).isRequired
};

WpIntro.defaultProps = {
	className: 'ap-intro'
};

/** @component */
export default WpIntro;
