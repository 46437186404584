import React from 'react';
import PropTypes from 'prop-types';
import Video from '../../Elements/Video/Video';

const WpVideo = (props) => {
	const {className, content} = props;
	const videoProps = {
		content: {
			caption: content.caption,
			videoEmbed: content.video_embed,
		},
	};
	return <Video className={className} {...videoProps} />;
};

WpVideo.propTypes = {
	/** Needed to overwrite styling via styled components. */
	className: PropTypes.string,
	content: PropTypes.shape({
		caption: PropTypes.string.isRequired,
		video_embed: PropTypes.string.isRequired,
	}).isRequired,
};

WpVideo.defaultProps = {
	className: 'ap-video',
};

/** @component */
export default WpVideo;
