import React from 'react';
import PropTypes from 'prop-types';
import {
	NurseStyled,
	ContactStyled,
	NursePhotoStyled,
	PhotoWrapper,
	IconBackgroundWrapper,
	ContentWrapper,
	HeadlineStyled,
	TextStyled,
	IconStyled,
	PhoneNumberStyled
} from './styles';

import {Icon} from '../../../Elements/Icon/Icon';
import Button from '../../../Elements/Button/Button';

const Nurse = ({firstname, lastname, img, phone, plz, resetNurse}) => (
	<NurseStyled className="ap-nurse">
		<IconBackgroundWrapper>
			<IconStyled name="schwesternservice" type="teaser" />
		</IconBackgroundWrapper>
		<PhotoWrapper>
			<NursePhotoStyled alt={img.title} src={img.url} />
		</PhotoWrapper>
		<ContentWrapper>
			<HeadlineStyled>Deine Schwester in der Nähe von {plz}</HeadlineStyled>
			<ContactStyled>
				<strong>
					{firstname} {lastname}:{' '}
				</strong>
				<PhoneNumberStyled>
					<Icon name="phone" /> {phone}
				</PhoneNumberStyled>
			</ContactStyled>
			<TextStyled
				content="<p>Da die GEMEINSAM STARK Schwestern/Pfleger viel für ihre Patienten
      im Einsatz sind, kann es sein, dass sie ggf. nicht ans Telefon gehen können.
      Die Schwestern/Pfleger rufen jedoch gerne zeitnah zurück, sofern deine Telefonnummer
      nicht unterdrückt ist. Sollte eine Schwestern/Pfleger abwesend sein, wir der Anruf
      direkt an die Vertretung umgeleitet.</p>"
			/>
			<Button onClick={resetNurse} text="PLZ zurücksetzen" theme="snow" />
		</ContentWrapper>
	</NurseStyled>
);

Nurse.propTypes = {
	firstname: PropTypes.string.isRequired,
	lastname: PropTypes.string.isRequired,
	img: PropTypes.shape({src: PropTypes.string, title: PropTypes.string, url: PropTypes.string}).isRequired,
	phone: PropTypes.string.isRequired,
	plz: PropTypes.string,
	resetNurse: PropTypes.func
};

Nurse.defaultProps = {
	plz: undefined,
	resetNurse: null
};

export default Nurse;
