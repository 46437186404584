import React from 'react';
import Slider from 'components/Container/Slider/Slider';

import ABA from 'assets/img/service-team/patientenbetreuer_aba.jpg';
import AST from 'assets/img/service-team/patientenbetreuer_ast.jpg';
import BKR from 'assets/img/service-team/patientenbetreuer_bkr.jpg';
import CRA from 'assets/img/service-team/patientenbetreuer_cra.jpg';
import DST from 'assets/img/service-team/patientenbetreuer_dst.jpg';
import ISC from 'assets/img/service-team/patientenbetreuer_isc.jpg';
import LBA from 'assets/img/service-team/patientenbetreuer_lba.jpg';
import MBS from 'assets/img/service-team/patientenbetreuer_mbs.jpg';
import OTR from 'assets/img/service-team/patientenbetreuer_otr.jpg';
import RHE from 'assets/img/service-team/patientenbetreuer_rhe.jpg';
import SAL from 'assets/img/service-team/patientenbetreuer_sal.jpg';
import TBA from 'assets/img/service-team/patientenbetreuer_tba.jpg';

import {ServiceTeamStyled, StyledImage} from './styles';

const team = [MBS, OTR, RHE, ABA, AST, BKR, CRA, DST, ISC, LBA, SAL, TBA];

const ServiceTeam = () => {
	return (
		<ServiceTeamStyled>
			<Slider maxShow={5} infinite>
				{team.map((item) => {
					return <StyledImage src={item} alt="Team" />;
				})}
			</Slider>
		</ServiceTeamStyled>
	);
};

/** @component */
export default ServiceTeam;
