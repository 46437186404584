import React from 'react';
import activityIcon from '../../../assets/img/cleo/Aktivitaet_Icon.svg';
import activityImage from '../../../assets/img/cleo/Aktivitaet.jpg';

import {Styled} from './styles';

export const CleoCard3 = () => (
	<>
		<Styled.Icon src={activityIcon} alt="Bild eines Fertigpens" />
		<Styled.Text>Aktivität:</Styled.Text>
		<Styled.TextBold>Das Wohlbefinden verbessern</Styled.TextBold>
		<Styled.Image src={activityImage} alt="Bild eines Fertigpens" />
		<Styled.Ul>
			<Styled.Li>Programme zu Ernährung, Mindfulness & Gehirn-Jogging</Styled.Li>
			<Styled.Li>Sportübungen in verschiedenen Schwierigkeitsgraden</Styled.Li>
			<Styled.Li>Von Experten entwickelt</Styled.Li>
		</Styled.Ul>
		<Styled.Spacer />
	</>
);
