import styled from 'styled-components';
// import {colors} from '../../../styles/variables';

export const BrochureOrderFormStyled = styled.div`
	max-width: 800px;
`;

export const RightAlignedParagraph = styled.p`
	text-align: right;
`;

export const BrochureSelectionItemStyled = styled.div`
	display: grid;
	grid-gap: 1rem;

	border: 1px red solid;
	margin-bottom: 2em;
`;
