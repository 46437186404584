import styled from 'styled-components';
import {GlobalFormStyle} from '../../../styles/global';
import {Textarea} from '../../Elements/Form/InputAndTextarea/InputAndTextarea';

export const ContactFormStyled = styled.div`
	${GlobalFormStyle};
`;

export const TextareaStyled = styled(Textarea)`
	width: 100%;
`;
