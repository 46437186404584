import React from 'react';
import chatIcon from '../../../assets/img/cleo/Chat_Icon.svg';
import chatImage from '../../../assets/img/cleo/Chat.jpg';

import {Styled} from './styles';

export const CleoCard4 = () => (
	<>
		<Styled.Icon src={chatIcon} alt="Bild eines Fertigpens" />
		<Styled.Text>Chat:</Styled.Text>
		<Styled.TextBold>Kontakt mit einem MS-Coach</Styled.TextBold>
		<Styled.Image src={chatImage} alt="Bild eines Fertigpens" />
		<Styled.Ul>
			<Styled.Li>Antworten auf Fragen rund um die MS</Styled.Li>
			<Styled.Li>
				Praktische Tipps auch außerhalb üblicher Sprechzeiten: Montag bis Freitag von 8 – 20 Uhr
			</Styled.Li>
			<Styled.Li>Ergänzung für Behandlung durch Arzt und Praxisteam</Styled.Li>
		</Styled.Ul>
		<Styled.Spacer />
	</>
);
