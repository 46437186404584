import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Column from '../../Layout/Grid/Column';
import Button from '../../Elements/Button/Button';
import Captcha from '../../Elements/Form/Captcha/Captcha';
import {Input, Textarea} from '../../Elements/Form/InputAndTextarea/InputAndTextarea';
import {forms} from '../../../assets/language/de';

import {submitCallbackService} from '../../../state/actions/callbackService';

import {CallbackFormStyled} from './styles';
import {captchaValidation, emailValidation, nonEmptyStringValidation} from '../../../utils/validations';

import SubmitForm from '../SubmitForm/SubmitForm';
import FormRow from '../../Elements/Form/FormRow/FormRow';
import Section from '../../Layout/Section/Section';
import Text from '../../Elements/Text/Text';
import Headline from '../../Elements/Headline/Headline';
import {withAsterisk} from '../../../utils/formHelpers';

const {initialValuesPreset, label, validationMessages} = forms.CallbackService;

// only fields defined in initialValues are automatically touched before validation,
// that's why we initialize here even with empty or falsy values
const initialValues = {
	captcha: '',
	email: '',
	forename: '',
	message: '',
	phone: '',
	subject: initialValuesPreset.subject,
	surname: ''
};

const validationSchema = Yup.object().shape({
	captcha: captchaValidation(validationMessages.captcha),
	email: emailValidation(validationMessages.email.ifEmpty, validationMessages.email.ifNotValid),
	forename: nonEmptyStringValidation(validationMessages.forename.ifEmpty),
	message: nonEmptyStringValidation(validationMessages.message),
	phone: nonEmptyStringValidation(validationMessages.phone.ifEmpty),
	subject: nonEmptyStringValidation(validationMessages.subject),
	surname: nonEmptyStringValidation(validationMessages.surname.ifEmpty)
});

/** @component */
const CallbackService = ({onSubmit}) => (
	<SubmitForm
		initialValues={initialValues}
		onSubmit={onSubmit}
		validationSchema={validationSchema}
		renderInnerForm={formikProps => (
			<CallbackFormStyled>
				<Section center>
					<Headline text="Sag, wenn es passt – wir rufen dich an." as="h2" />
					<FormRow>
						<Column>
							<Text content="Die mit einem * gekennzeichneten Felder sind Pflichtfelder." />
						</Column>
					</FormRow>
					<FormRow>
						<Column md={6}>
							<Input label={withAsterisk(label.forename)} name="forename" />
						</Column>
						<Column md={6}>
							<Input label={withAsterisk(label.surname)} name="surname" />
						</Column>
					</FormRow>
					<FormRow>
						<Column md={6}>
							<Input label={withAsterisk(label.email)} name="email" />
						</Column>
						<Column md={6}>
							<Input label={withAsterisk(label.phone)} name="phone" />
						</Column>
					</FormRow>
					<FormRow>
						<Column>
							<Input label={withAsterisk(label.subject)} name="subject" />
						</Column>
					</FormRow>

					<FormRow>
						<Column>
							<Textarea label={withAsterisk(label.message)} name="message" />
						</Column>
					</FormRow>
					<FormRow>
						<Column>
							<Captcha name="captcha" />
						</Column>
					</FormRow>
					<FormRow>
						<Column>
							<Button disabled={formikProps.isSubmitting} text="Abschicken" type="submit" />
						</Column>
					</FormRow>
				</Section>
			</CallbackFormStyled>
		)}
	/>
);

CallbackService.propTypes = {
	onSubmit: PropTypes.func
};

CallbackService.defaultProps = {
	onSubmit: undefined
};

const mapDispatchToProps = dispatch => ({
	onSubmit: data => dispatch(submitCallbackService(data))
});

export default connect(
	null,
	mapDispatchToProps
)(CallbackService);
