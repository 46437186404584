import styled from 'styled-components';
import {media} from '../../../styles/utils';

const Icon = styled.div`
	background: url(${(props) => props.src});
	width: 2rem;
	height: 2rem;
	padding-bottom: 0.5rem;
	background-size: contain;
	background-repeat: no-repeat;
`;

const Image = styled.img`
	width: 80%;
	margin-top: 1rem;
	margin-right: 1rem;
	background-size: contain;
	background-repeat: no-repeat;
`;

const Ul = styled.ul`
	margin-left: 1em;
`;

const Li = styled.li`
	list-style: disc;
	font-size: 1em;
	${media.xxxl`
	font-size: 1.2em;
	`};
`;

const Text = styled.div`
	font-size: 1em;

	${media.xxxl`
	font-size: 1.2em;
	`};
`;

const TextBold = styled.div`
	font-size: 1.2em;
	font-weight: 900;

	${media.xxxl`
	font-size: 1.4em;
	`};
`;

const Spacer = styled.div`
	margin-bottom: 2em;
`;

export const Styled = {Image, Icon, Ul, Li, Text, TextBold, Spacer};
