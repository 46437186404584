import WpText from '../WpText/WpText';
import WpHeadline from '../WpHeadline/WpHeadline';
import WpInfobox from '../WpInfobox/WpInfobox';
import WpImage from '../WpImage/WpImage';
import WpList from '../WpList/WpList';
import WpButton from '../WpButton/WpButton';
import WpQuote from '../WpQuote/WpQuote';
import WpSpotify from '../WpSpotify/WpSpotify';
import WpSpecial from '../WpSpecial/WpSpecial';
import WpVideo from '../WpVideo/WpVideo';
import WpIntro from '../WpIntro/WpIntro';
import WpTeaserGroup from '../WpTeaserGroup/WpTeaserGroup';
import WpServiceTeaser from '../WpServiceTeaser/WpServiceTeaser';
import WpDownloadButton from '../WpDownloadButton/WpDownloadButton';
import WpCookiesPage from '../WpCookiesPage/WpCookiesPage';
import WpAccordion from '../WpAccordion/WpAccordion';
import WpReleaseNumbers from '../WpReleaseNumbers/WpReleaseNumbers';
import WpSideBubble from '../WpSideBubble/WpSideBubble';
import WpSlider from '../WpSlider/WpSlider';
import WpDynamicSlider from '../WpDynamicSlider/WpDynamicSlider';
import WpTabs from '../WpTabs/WpTabs';
import WpIconText from '../WpIconText/WpIconText';
import WpTabAnchorLink from '../WpTabAnchorLink/WpTabAnchorLink';
import WpAnchorId from '../WpAnchorId/WpAnchorId';

export const reactComponents = {
	WordPressAcf_headline: WpHeadline,
	WordPressAcf_text: WpText,
	WordPressAcf_intro: WpIntro,
	WordPressAcf_infobox: WpInfobox,
	WordPressAcf_image: WpImage,
	WordPressAcf_list: WpList,
	WordPressAcf_button: WpButton,
	WordPressAcf_downloadbutton: WpDownloadButton,
	WordPressAcf_quote: WpQuote,
	WordPressAcf_special: WpSpecial,
	WordPressAcf_teasergroup: WpTeaserGroup,
	WordPressAcf_serviceteaser: WpServiceTeaser,
	WordPressAcf_video: WpVideo,
	WordPressAcf_releasenumbers: WpReleaseNumbers,
	WordPressAcf_cookiespage: WpCookiesPage,
	WordPressAcf_accordion: WpAccordion,
	WordPressAcf_sidebubble: WpSideBubble,
	WordPressAcf_spotify: WpSpotify,
	WordPressAcf_slider: WpSlider,
	WordPressAcf_dynamicslider: WpDynamicSlider,
	WordPressAcf_tabs: WpTabs,
	WordPressAcf_icontextlist: WpIconText,
	WordPressAcf_tab_anchor_link: WpTabAnchorLink,
	WordPressAcf_anchor_link_id: WpAnchorId,
};
