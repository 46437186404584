import React from 'react';
import PropTypes from 'prop-types';

import UserQuery from 'components/Content/UserQuery/UserQuery';
import ServiceTeam from 'components/Content/ServiceTeam/ServiceTeam';
import EMTeaser from 'components/Content/EMTeaser/EMTeaser';

import Contact from '../../Container/Contact/Container';
import MSSlider from '../../Content/MSSlider/MSSlider';
import EventVideo from '../../Container/SnippetIntegration/EventVideo';
import EventChat from '../../Container/SnippetIntegration/EventChat';
import DossierIconBox from '../../Container/DossierIconBox/DossierIconBox';
import ServiceTeaser from '../../Content/ServiceTeaser/ServiceTeaser';
import CallbackService from '../../Container/CallbackService/CallbackService';
import NursesService from '../../Container/NursesService/NursesService';
import BrochureOrder from '../../Container/BrochureOrder/BrochureOrder';
import AutoEnroller from '../../Container/AutoEnroller/AutoEnroller';
import {TherapyTool} from '../../Container/TherapyInformation/TherapyTool/TherapyTool';
import ProductInformation from '../../Container/ProductInformation/ProductInformation';
import EventBlock1 from '../../Container/EventBlock/EventBlock1';
import EventBlock2 from '../../Container/EventBlock/EventBlock2';
import EventBlock3 from '../../Container/EventBlock/EventBlock3';
import CleoBlock1 from '../../Container/CleoBlock/CleoBlock1';
import CleoBlock2 from '../../Container/CleoBlock/CleoBlock2';
import {CleoCard1} from '../../Container/CleoCard/CleoCard1';
import {CleoCard2} from '../../Container/CleoCard/CleoCard2';
import {CleoCard3} from '../../Container/CleoCard/CleoCard3';
import {CleoCard4} from '../../Container/CleoCard/CleoCard4';
import {CleoReview1} from '../../Container/CleoReview/CleoReview1';
import {CleoReview2} from '../../Container/CleoReview/CleoReview2';
import {CleoReview3} from '../../Container/CleoReview/CleoReview3';
import {CleoReview4} from '../../Container/CleoReview/CleoReview4';
import {CleoWeb} from '../../Container/CleoReview/CleoWeb';
import {CleoFace} from '../../Container/CleoReview/CleoFace';
import {CleoStore} from '../../Container/CleoReview/CleoStore';

const WpSpecial = (props) => {
	switch (props.content.element) {
		case 'contact':
			return <Contact />;
		case 'callback_service':
			return <CallbackService />;
		case 'ms_slider':
			return <MSSlider />;
		case 'user_query':
			return <UserQuery />;
		case 'service_teaser':
			return <ServiceTeaser />;
		case 'em_teaser':
			return <EMTeaser />;
		case 'service_team':
			return <ServiceTeam />;
		case 'schwesternservice':
			return <NursesService />;
		case 'gebrauchsinformationen':
			return <ProductInformation />;
		case 'broschuerenbestellung':
			return <BrochureOrder />;
		case `gs_onlineformular`:
			return <AutoEnroller />;
		case 'produktinformationen':
			return <TherapyTool lang="de" />;
		case 'produktinformationen_ar':
			return <TherapyTool lang="ar" />;
		case 'produktinformationen_en':
			return <TherapyTool lang="en" />;
		case 'produktinformationen_ru':
			return <TherapyTool lang="ru" />;
		case 'produktinformationen_tr':
			return <TherapyTool lang="tr" />;
		case `dossiers`:
			return <DossierIconBox />;
		case `event_video`:
			return <EventVideo />;
		case `event_chat`:
			return <EventChat />;
		case `event_1`:
			return <EventBlock1 />;
		case `event_2`:
			return <EventBlock2 />;
		case `event_3`:
			return <EventBlock3 />;
		case `cleo_1`:
			return <CleoBlock1 />;
		case `cleo_2`:
			return <CleoBlock2 />;
		case `cleo_card_1`:
			return <CleoCard1 />;
		case `cleo_card_2`:
			return <CleoCard2 />;
		case `cleo_card_3`:
			return <CleoCard3 />;
		case `cleo_card_4`:
			return <CleoCard4 />;
		case `cleo_review_1`:
			return <CleoReview1 />;
		case `cleo_review_2`:
			return <CleoReview2 />;
		case `cleo_review_3`:
			return <CleoReview3 />;
		case `cleo_review_4`:
			return <CleoReview4 />;
		case `cleo_web_block`:
			return <CleoWeb />;
		case `cleo_face_block`:
			return <CleoFace />;
		case `cleo_review_png`:
			return <CleoStore />;
		default:
			return null;
	}
};

WpSpecial.propTypes = {
	content: PropTypes.shape({
		element: PropTypes.oneOf([
			'glossary',
			'ms_slider',
			'user_query',
			'service_teaser',
			'em_teaser',
			'diroximelfumarat',
			'ms_tv',
			'callback_service',
			'schwesternservice',
			'gebrauchsinformationen',
			'produktinformationen',
			'produktinformationen_ar',
			'produktinformationen_en',
			'produktinformationen_ru',
			'produktinformationen_tr',
			'contact',
			'broschuerenbestellung',
			'gs_onlineformular',
			'dossiers',
			'event_1',
			'event_2',
			'event_3',
			'event_chat',
			'event_video',
			'cleo_1',
			'cleo_2',
			'cleo_card_1',
			'cleo_card_2',
			'cleo_card_3',
			'cleo_card_4',
			'cleo_review_1',
			'cleo_review_2',
			'cleo_review_3',
			'cleo_review_4',
			'cleo_review_png',
			'cleo_web_block',
			'cleo_face_block',
		]),
	}).isRequired,
};

WpSpecial.defaultProps = {};

/** @component */
export default WpSpecial;
