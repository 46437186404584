import styled from 'styled-components';
import {media} from 'styles/utils';

import Image from '../../Elements/Image/Image';
import Text from '../../Elements/Text/Text';

export const Wrapper = styled.div`
	width: 100% !important;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 20px 0;

	${media.md`
		flex-direction: row;
	`};
`;

export const StyledImage = styled(Image)`
	width: 100px;
	min-width: 100px;
	max-width: 100px;
	margin-bottom: 20px;

	${media.md`
		margin: 0;
		margin-right: 20px;
	`};
`;

export const StyledText = styled(Text)`
	margin-bottom: 0px !important;
	p {
		margin-bottom: 0px !important;
	}
`;
