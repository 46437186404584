import React, {useState} from 'react';
import {TextStyled, IconButtonStyled, FormStyled} from './styles';
import IconInfobox from '../../Elements/IconInfobox/IconInfobox';
import Nurse from './Nurse/Nurse';
import {Icon} from '../../Elements/Icon/Icon';

const NursesService = () => {
	const [plz, setPlz] = useState('');
	const [error, setError] = useState(false);
	const [nurse, setNurse] = useState('');

	const handleSubmit = e => {
		e.preventDefault();
		if (plz.match(/^[0-9]*$/gm) && plz.trim() !== '') {
			fetch(`${process.env.GATSBY_NURSE_API}/${plz}`)
				.then(response => response.json())
				.then(data => {
					if (data !== null) {
						setNurse({
							firstname: data.firstname,
							lastname: data.lastname,
							phone: data.phone,
							img: data.image,
							plz
						});
					} else {
						setError(true);
					}
				})
				.catch(() => setError(true));
		} else {
			setError(true);
			setPlz('');
		}
		setPlz('');
	};

	const resetForm = () => {
		setNurse('');
		setPlz('');
	};

	const unsetError = () => {
		setError(false);
	};

	return (
		<>
			{nurse && (
				<>
					<Nurse
						firstname={nurse.firstname}
						lastname={nurse.lastname}
						phone={nurse.phone}
						img={nurse.img}
						plz={nurse.plz}
						resetNurse={() => resetForm('')}
					/>
				</>
			)}
			{!nurse && (
				<IconInfobox
					className="ap-nurses"
					title="Schwester/Pfleger in deiner Nähe finden!"
					iconName="schwesternservice"
					iconType="teaser"
					theme="service">
					<TextStyled
						content="Du möchtest wissen, welche GEMEINSAM STARK Schwestern oder Pfleger für dich
						zuständig sind und dich mit einer Einweisung beim Start deiner verordneten MS-Therapie
						unterstützen können? Gib nachfolgend die Postleitzahl einer behandelnden Neurologie-Praxis/Klinik ein."
					/>

					<FormStyled onSubmit={handleSubmit} hasError={error}>
						<input
							type="text"
							value={plz}
							onClick={unsetError}
							onKeyPress={unsetError}
							onChange={e => setPlz(e.target.value)}
							placeholder={
								error
									? 'Keine Schwestern wurden in der Nähe der eingegebenen PLZ gefunden.'
									: 'Postleitzahl hier eingeben'
							}
						/>
						<IconButtonStyled type="submit" theme="white">
							<Icon name="search" />
						</IconButtonStyled>
					</FormStyled>
				</IconInfobox>
			)}
		</>
	);
};

/** @component */
export default NursesService;
