import styled from 'styled-components';
import Link from '../../Elements/Link/Link';
import {withUnit} from '../../../styles/utils';
import {sizes, colors} from '../../../styles/variables';
import {HeadlineStyledH3} from '../../Elements/Headline/styles';
import Column from '../../Layout/Grid/Column';
import Row from '../../Layout/Grid/Row';

export const RowStyled = styled(Row)``;

export const ColumnStyled = styled(Column)`
	display: flex;
`;

export const ProductCard = styled.div`
	width: 100%;
	background: ${colors.snow};
	overflow: hidden;
`;

export const ProductContainerStyled = styled.div`
	box-sizing: border-box;
	padding: 2em 1.5em;
	position: relative;
	overflow: hidden;
	height: 100%;
`;

export const ProductTitle = styled(HeadlineStyledH3)`
	color: ${colors.black};
`;

export const ProductInfoContainerStyled = styled.div`
	margin-bottom: ${withUnit(sizes.distance.base / 2)};
`;

export const DownloadStyled = styled(Link)`
	margin-bottom: ${withUnit(sizes.distance.base / 2)};

	svg {
		width: 1em;
		height: 1em;
	}
`;

export const ProductInfo = styled.div`
	position: relative;
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
`;

export const BackgroundIcon = styled.div`
	transform: rotate(-10deg);
	color: ${colors.silver};
	position: absolute;
	bottom: -5em;
	right: -8em;
	opacity: 0.2;
	overflow: hidden;
	pointer-events: none;
`;
