import React from 'react';
import config from '../../../config';

// export data
export const countries = config.formselectdata.country.map(({uid, country_name}) => ({
	type: uid,
	label: country_name
}));

export const federalStates = config.formselectdata.state.map(({uid, state_name}) => ({
	type: uid,
	label: state_name
}));

export const salutations = ['Frau', 'Herr'].map(label => ({
	type: label, // because we select "Frau" or "Herr", not an id
	label
}));

// TODO replace brochureLists with API calls
export const brochureLists = {
	forMsPatients: [
		{
			headline: 'MS verstehen',
			label: 'Was ist MS und wie wird sie behandelt?'
		},
		{
			headline: 'Den Alltag meistern',
			label: 'Nützliche Tipps & Ideen, die den Alltag erleichtern'
		},
		{
			headline: 'Meinen eigenen Weg gehen',
			label: 'Infos & Anregungen für ein selbstbestimmtes Leben'
		},
		{
			headline: 'Meine Stärken erkennen',
			label: 'Wie man Krisen bewältigt und gestärkt hervorgeht'
		},
		{
			headline: 'Rechtzeitig auf Veränderungen reagieren',
			label: 'Die Therapie an die aktuelle Situation anpassen'
		}
	],
	forFamilyMembers: [
		{
			headline: 'Mama oder Papa hat MS',
			label: 'Ich erkläre dir, was los ist'
		},
		{
			headline: 'Herausforderung MS meistern',
			label: 'Information und Tipps für Angehörige'
		}
	]
};
