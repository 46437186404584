import React from 'react';
import {BrochureSelectionItemStyled} from './styles';

import {Checkbox2} from '../../Elements/Form/Checkbox/Checkbox';
import Headline from '../../Elements/Headline/Headline';

const BrochureSelectionItem = ({headline, label}) => (
	<BrochureSelectionItemStyled>
		<Checkbox2
			labelContent={
				<React.Fragment>
					<img src="http://placekitten.com/g/796/396" height="75" width="100" />
					<div style={{display: 'inline-block', width: '50%'}}>
						<div>{headline}</div>
						<Headline as="h5" text={label} />
					</div>
				</React.Fragment>
			}
			name={label}
		/>
	</BrochureSelectionItemStyled>
);

export default BrochureSelectionItem;
