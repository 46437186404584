import styled, {css} from 'styled-components';
import {HeadlineStyledH5, HeadlineStyledH6} from '../../Headline/styles';
import {colors, fonts, sizes} from '../../../../styles/variables';
import {media, withUnit} from '../../../../styles/utils';
import Image from '../../Image/Image';

export const DEFAULT = 'default';

export const appearances = ['', DEFAULT, 'shadow', 'full-image'];

export const themes = ['orange', 'blue'];

export const themeColors = {
	orange: {
		text: colors.carrot,
	},
	blue: {
		text: colors.navy,
	},
};

const TeaserShadowStyles = css`
	box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);

	${media.lg`
		box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
	`};
`;

export const TeaserImageStyled = styled(Image)`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	transition: transform 1s ease;

	transform: translate(-50%, -50%) scale(1.2) rotate(2deg);
	transform-origin: 50% 50%;
`;

export const TeaserImageWrapperStyled = styled.div`
	height: 220px;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
	flex: 1 1 auto;

	${media.md`
		height: 300px;
	`};
`;

export const TeaserContentStyled = styled.div`
	box-sizing: border-box;
	padding: 1em;
	overflow: hidden;
	flex: none;
	//height: 60%;
	//display: flex;
	//flex-direction: column;
	//justify-content: flex-start;
	text-align: center;

	> * {
		flex: 1 1 auto;
	}
`;

export const TeaserStyled = styled.div`
	position: relative;
	z-index: 1;
	//display: flex;
	//justify-content: flex-end;
	//flex-direction: column;
	box-sizing: border-box;

	background-color: ${colors.white};
	text-decoration: none;
	color: ${colors.black};
	text-align: left;
	height: 100%;

	${(props) => (props.item.appearance === 'shadow' ? TeaserShadowStyles : null)};

	&:hover {
		${TeaserImageStyled} {
			transform: translate(-50%, -50%) scale(1) rotate(0deg);
		}
	}

	.ap-search-postlist & {
		box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
	}
`;

export const TeaserTitleStyled = styled(HeadlineStyledH5)`
	flex: 0 1 auto;

	// sadly have to boost specificity :(
	&& {
		margin-bottom: 0;
	}
`;

export const ToplineStyled = styled(HeadlineStyledH6)`
	font-family: ${fonts.sans_serif};
	color: ${colors.navy};
	color: ${(props) => props.theme.text};
	font-size: 0.8em;
`;

export const TeaserTextStyled = styled.div`
	overflow: hidden;
	margin-top: ${withUnit(sizes.distance.base / 2)};
	font-size: 0.9em;
`;
