import React from 'react';
import {Container, LogoContainer, SectionStyled, StyledText} from './styles';
import LogoCommunity from '../../Layout/Logo/LogoCommunity';
import Button from '../../Elements/Button/Button';

const EventBlock1 = () => (
	<Container center>
		<LogoContainer>
			<LogoCommunity />
		</LogoContainer>
		<SectionStyled center>
			<StyledText large>
				Hier kannst du anderen Betroffenen Fragen stellen oder eigene Erfahrungen teilen.
			</StyledText>
			<Button type="link" to="/ms-community/" text="Zum Diskussionsforum" theme="white" />
		</SectionStyled>
	</Container>
);

export default EventBlock1;
