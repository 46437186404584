import styled from 'styled-components';
import {media} from '../../../styles/utils';

const Icon = styled.div`
	background: url(${(props) => props.src});
	width: 0.7rem;
	height: 0.7rem;
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
`;

const Image = styled.img`
	width: 2em;
	height: 2em;
	margin-right: 0.75rem;
	background-size: contain;
	background-repeat: no-repeat;
`;

const BigImage = styled.img`
	width: 10em;
	height: 5em;
	margin-right: 0.75rem;
	background-size: contain;
	background-repeat: no-repeat;
`;

const Block = styled.div`
	display: ${(props) => (props.flex ? 'flex' : 'inline-block')};
	padding-right: 0.2rem;
	padding-bottom: ${(props) => (props.flex ? '0.75rem' : '')};
`;

const Text = styled.div`
	font-size: 0.9em;
	${media.xxxl`
	font-size: 1.2em;
	`};
`;

const InlineText = styled.div`
	display: inline-block;
	font-size: 0.9em;
	font-weight: ${(props) => (props.thin ? 400 : 700)};
	${media.xxxl`
	font-size: 1.2em;
	`};
`;

const Spacer = styled.div`
	margin-bottom: 2em;
`;

const Bold = styled.span`
	font-weight: 800;
`;

const BreakRow = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 0.5rem;

	${media.xxl`
	flex-direction: row;
	`};
`;

export const Styled = {Icon, BreakRow, Block, Text, BigImage, Image, Bold, InlineText, Spacer};
