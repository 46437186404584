import styled from 'styled-components';
import {fonts, sizes, colors} from '../../../styles/variables';
import {media, orangeBorder, withUnit} from '../../../styles/utils';
import {Icon} from '../Icon/Icon';

export const QuoteStyled = styled.blockquote`
	position: relative;
	font-family: ${fonts.serif};
	font-weight: 700;
	line-height: 1.6;
	font-style: italic;
	text-align: center;
	font-size: 1.2em;
	padding: ${withUnit(sizes.distance.base / 2)};
	margin-bottom: ${withUnit(sizes.distance.base / 2)};

	${orangeBorder('center')};

	> div > *:last-child {
		margin-bottom: 0;
	}

	${media.md`
		padding: ${withUnit(sizes.distance.base)};
		margin-bottom: ${withUnit(sizes.distance.base)};
	`};
`;

export const IconStyled = styled(Icon)`
	position: absolute;
	display: block;
	top: 2%;
	left: 15%;
	width: 50px;
	height: 50px;
	z-index: -1;

	svg {
		fill: ${colors.carrot};
		opacity: 0.5;
		width: 50px;
		height: 50px;
	}
`;
