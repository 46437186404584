import styled from 'styled-components';
import {motion} from 'framer-motion';
import {gradientCandyCarrotSun} from '../../../styles/utils';
import Button from '../../Elements/Button/Button';
import Image from '../../Elements/Image/Image';

export const Bubble = styled(motion.div)`
	position: fixed;
	height: 240px;
	width: 240px;
	right: -20px;
	top: calc(50% - 120px);
	${gradientCandyCarrotSun()};
	border-radius: 50%;
	z-index: 999;
`;

export const SideBubbleClosed = styled.h5`
	display: flex;
	position: absolute;
	color: white;
	bottom: 9px;
	margin: 0;
	text-align: center;
	width: 100%;
	justify-content: center;
`;

export const SideBubbleTitleImage = styled(Image)`
	position: relative;
	height: 100px;
	margin-top: -20px;

	img {
		display: block;
		max-width: 100%;
		max-height: 100%;
		object-fit: contain !important;
	}
`;

export const SideBubbleText = styled.div`
	display: flex;
	color: white;
	text-align: center;
	width: 100%;
	justify-content: center;
	font-size: 17px;
	padding-top: 0.6rem;
	line-height: 1;
	box-sizing: border-box;
	margin-top: 0px;
	margin-bottom: 6px;
`;

export const SideBubbleButton = styled(Button)`
	display: flex;
	font-size: 17px;
	height: 30px;
	margin-top: 0;
	padding: 0;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
`;

export const SideBubbleContent = styled.div`
	width: 75%;
	margin: 0 auto;
`;
