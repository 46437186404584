import React from 'react';
import PropTypes from 'prop-types';

import {StyledTeaserSelection, Headline, Content} from './styles';

const TeaserSelection = ({children, active, className}) => {
	return (
		<StyledTeaserSelection className={className} active={active}>
			<Headline>Passend zu deiner Auswahl</Headline>

			<Content>{children}</Content>
		</StyledTeaserSelection>
	);
};

TeaserSelection.propTypes = {
	children: PropTypes.object.isRequired,
	active: PropTypes.bool,
	className: PropTypes.string,
};

TeaserSelection.defaultProps = {
	className: 'ap-teaserselection',
	active: false,
};

/** @component */
export default TeaserSelection;
