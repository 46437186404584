export const filterOne = [
	{
		id: 1,
		value: 'Ja, erstmal stöbern.',
		paths: ['leben-mit-ms', 'service/app', 'service/dein-ernaehrungsmanager'],
	},
	{
		id: 2,
		value: 'Ich will mich gezielt informieren.',
		paths: ['service/gemeinsam-stark', 'service/ms-behandlung', 'service/dein-ernaehrungsmanager'],
	},
];
export const filterTwo = [
	{
		id: 1,
		value: 'Ich suche meine Infos selbst.',
		paths: ['community', 'service/broschueren', 'service/materialien', 'service/dein-ernaehrungsmanager'],
	},
	{
		id: 2,
		value: 'Gerne ein MS-Coach.',
		paths: ['service/service-center', 'service/service-team', 'service/gemeinsam-stark'],
	},
];
