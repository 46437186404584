import styled from 'styled-components';
import {colors, fonts} from '../../../../styles/variables';
import {media} from '../../../../styles/utils';

export const StyledFilterButton = styled.button`
	cursor: pointer;
	position: relative;
	outline: none;
	border-radius: 1.5rem;
	padding: 0.8rem;
	margin: 0.5rem;
	width: 9rem;
	line-height: 1.5;
	background: ${(props) => (props.isActive ? `${colors.gradient}` : 'white')};
	color: ${(props) => (props.isActive ? 'white' : `${colors.grey}`)};
	border: none;
	font-family: ${fonts.sans_serif};
	font-size: 0.8em;
	transition: all 200ms;

	${media.lg`
		&:hover {
			background: ${colors.gradient};
			color: white;
		}
	`};

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		margin: -2px;
		border-radius: 1.6rem;
		background: ${colors.gradient};
	}
`;

export const Line = styled.div`
	width: 2px;
	display: none;
	height: ${(props) => (props.isActive ? '250px' : '0')};
	opacity: ${(props) => (props.isActive ? '1' : '0')};
	background: red;
	position: absolute;
	top: 0;
	z-index: -2;
	left: 50%;
	transform: translateX(-50%);
	transition: all 1000ms;

	${media.lg`
		display: block;
	`};
`;
