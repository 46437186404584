import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import Product from './Product/Product';

import {ColumnStyled, RowStyled, ProductCard} from './styles';

const ProductContainer = () => (
	<StaticQuery
		query={graphql`
			query {
				allWordpressWpProductinfo(sort: {fields: acf___order}) {
					edges {
						node {
							acf {
								order
								name
								strength
								vector
								prescription
								substance
								icon
								usage_info {
									link {
										url
										title
									}
								}
							}
						}
					}
				}
			}
		`}
		render={data => (
			<RowStyled>
				{data.allWordpressWpProductinfo.edges.map(el => (
					<ColumnStyled md={6} lg={12} xl={6} key={el.node.acf.name}>
						<ProductCard>{Product(el.node.acf)}</ProductCard>
					</ColumnStyled>
				))}
			</RowStyled>
		)}
	/>
);
export default ProductContainer;
