import React from 'react';
import PropTypes from 'prop-types';
import {ReleaseNumberStyled} from './styles';

const ReleaseNumbers = props => {
	const {releaseNumbers} = props;
	if (releaseNumbers === null || releaseNumbers === '') {
		return null;
	}
	return <ReleaseNumberStyled>{releaseNumbers}</ReleaseNumberStyled>;
};

ReleaseNumbers.propTypes = {
	releaseNumbers: PropTypes.string
};

ReleaseNumbers.defaultProps = {
	releaseNumbers: null
};

/** @component */
export default ReleaseNumbers;
