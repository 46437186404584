import React from 'react';
import PropTypes from 'prop-types';
import Column from '../../Layout/Grid/Column';

const WpColumn1 = ({children}) => <Column md={4}>{children}</Column>;

WpColumn1.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

WpColumn1.defaultProps = {
	children: null,
};

/** @component */
export default WpColumn1;
