import React from 'react';
import PropTypes from 'prop-types';
import SideBubble from '../../Container/SideBubble/SideBubble';

const WpSideBubble = props => {
	if (!props) {
		return null;
	}
	const {content} = props;
	return <SideBubble content={content} />;
};

WpSideBubble.propTypes = {
	content: PropTypes.shape({
		content: PropTypes.string
	}).isRequired
};

WpSideBubble.defaultProps = {};

/** @component */
export default WpSideBubble;
