import React from 'react';
import PropTypes from 'prop-types';
import {v1 as uuidv1} from 'uuid';

import Tabs from 'components/Elements/Tabs/Tabs';
import WpText from '../WpText/WpText';
import WpHeadline from '../WpHeadline/WpHeadline';
import WpInfobox from '../WpInfobox/WpInfobox';
import WpImage from '../WpImage/WpImage';
import WpList from '../WpList/WpList';
import WpButton from '../WpButton/WpButton';
import WpQuote from '../WpQuote/WpQuote';
import WpVideo from '../WpVideo/WpVideo';
import WpIntro from '../WpIntro/WpIntro';
import WpDynamicSlider from '../WpDynamicSlider/WpDynamicSlider';
import WpDownloadButton from '../WpDownloadButton/WpDownloadButton';
import WpIconText from '../WpIconText/WpIconText';
import WpAnchorId from '../WpAnchorId/WpAnchorId';

const reactComponents = {
	headline: WpHeadline,
	text: WpText,
	intro: WpIntro,
	infobox: WpInfobox,
	image: WpImage,
	list: WpList,
	button: WpButton,
	downloadbutton: WpDownloadButton,
	quote: WpQuote,
	video: WpVideo,
	dynamicslider: WpDynamicSlider,
	icontextlist: WpIconText,
	anchor_link_id: WpAnchorId,
};

const WpTabs = (props) => {
	const {
		className,
		content: {tabs},
	} = props;

	const itemArray = [];

	for (let i = 0; i < tabs.length; i += 1) {
		const contentElements = tabs[i].content_elements;
		const renderComponents = contentElements.map((elem) => {
			const Component = reactComponents[elem.acf_fc_layout];
			return Component ? <Component key={uuidv1()} content={elem} /> : null;
		});
		const entryObject = {
			tabname: tabs[i].tabname,
			tabid: tabs[i].tabid,
			content: renderComponents,
		};
		itemArray.push(entryObject);
	}

	return <Tabs items={itemArray} className={className} />;
};

WpTabs.propTypes = {
	className: PropTypes.string,
	content: PropTypes.shape({tabs: PropTypes.arrayOf(PropTypes.shape(PropTypes.string, PropTypes.any))}).isRequired,
};

WpTabs.defaultProps = {
	className: 'ap-tabs',
};

/** @component */
export default WpTabs;
