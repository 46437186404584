import styled from 'styled-components';

import Image from 'components/Elements/Image/Image';
import {media} from '../../../styles/utils';

export const ServiceTeamStyled = styled.div`
	overflow: hidden;
	position: relative;
	margin: 4rem 0;

	&::before,
	&::after {
		content: '';
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 1) 10%,
			rgba(255, 255, 255, 0) 100%
		);
		width: 15%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		pointer-events: none;
	}

	&::after {
		background: linear-gradient(
			270deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 1) 10%,
			rgba(255, 255, 255, 0) 100%
		);
		right: 0;
		left: unset;
	}

	.slider {
		padding-bottom: 50px !important;
	}

	.slick-active {
		transition: 300ms;
	}

	.slick-arrow-left {
		right: 0 !important;
		left: 32% !important;
		bottom: -60px !important;

		${media.md`
			left: 40% !important;
		`};

		${media.lg`
			left: 37% !important;
		`};

		${media.xl`
			left: 40% !important;
		`};

		${media.xxl`
			left: 42% !important;
		`};
	}

	.slick-arrow-right {
		right: 32% !important;
		bottom: -60px !important;

		${media.md`
			right: 40% !important;
		`};

		${media.lg`
			right: 37% !important;
		`};

		${media.xl`
			right: 40% !important;
		`};

		${media.xxl`
			right: 42% !important;
		`};
	}
`;

export const StyledImage = styled(Image)`
	min-width: 100%;
	min-height: 100%;
	overflow: hidden;
	border-radius: 14px;
	box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2);
`;
