import React from 'react';
import star from '../../../assets/img/cleo/Star_orange.svg';

import {Styled} from './styles';

export const CleoReview4 = () => (
	<>
		<Styled.Block>
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
		</Styled.Block>

		<Styled.InlineText>Hilfreiche App</Styled.InlineText>
		<Styled.Text>
			„Da ich sehr vergesslich bin, hilft mir diese App über den Tag. Gibt tolle Tipps und interessante Berichte.“
		</Styled.Text>
		<Styled.Spacer />
	</>
);
