import React from 'react';
import {
	Container,
	TextBlockStyled,
	StyledText,
	Bold,
	IconStyled,
	FlexBlock,
	InlineBlock,
	InfoIconStyled,
} from './styles';
import Button from '../../Elements/Button/Button';

const EventBlock2 = () => (
	<Container center={false}>
		<TextBlockStyled>
			<StyledText bold>In zwei einfachen Schritten zum Event:</StyledText>
			<FlexBlock>
				<IconStyled>1</IconStyled> <StyledText flat>Link öffnen</StyledText>
			</FlexBlock>
			<InlineBlock>
				<Button type="link" to="/spezial/msverbindet-live" text="#MSverbindet - Zum Event" />

				<StyledText small>
					Oder hier:{' '}
					<a href="https://mein.ms-life.de/spezial/msverbindet-live">
						mein.ms-life.de/spezial/msverbindet-live
					</a>
				</StyledText>
			</InlineBlock>
			<FlexBlock>
				<IconStyled>2</IconStyled>{' '}
				<StyledText flat>
					Passwort eingeben: <Bold>WELTMSTAG2021</Bold>
				</StyledText>
			</FlexBlock>
		</TextBlockStyled>
		<InfoIconStyled type="teaser" name="info" />
	</Container>
);

export default EventBlock2;
