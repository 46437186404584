import React from 'react';
import PropTypes from 'prop-types';
import Spotify from '../../Elements/Spotify/Spotify';

const WpSpotify = (props) => {
	const {className, content} = props;
	return <Spotify className={className} content={content.playlist} />;
};

WpSpotify.propTypes = {
	/** Needed to overwrite styling via styled components. */
	className: PropTypes.string,
	content: PropTypes.shape({
		playlist: PropTypes.string.isRequired,
	}).isRequired,
};

WpSpotify.defaultProps = {
	className: 'ap-spotify',
};

/** @component */
export default WpSpotify;
