import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import Row from '../../Layout/Grid/Row';
import ServiceTeaser from '../../Elements/Teaser/ServiceTeaser360/ServiceTeaser';
import {filterOne, filterTwo} from './filterData';
import FilterButton from '../../Elements/Teaser/FilterButton/FilterButton';
import TeaserSelection from '../../Elements/Teaser/TeaserSelection/TeaserSelection';
import {Paragraph, Headline, Title, FlexColumn, TextWrapper, TeaserWrapper, TeaserCard} from './styles';

const activeVariants = {
	visible: () => ({
		opacity: 1,
		top: 0,
		left: 0,
		position: 'relative',
		scale: 1,
	}),
	hidden: {
		opacity: 0,
		top: 300,
		left: '50%',
		position: 'absolute',
		scale: 0.5,
	},
};
const passiveVariants = {
	visible: () => ({
		opacity: 1,
		top: 0,
		left: 0,
		position: 'relative',
		scale: 1,
	}),
	hidden: {
		opacity: 0,
		top: -300,
		left: '50%',
		position: 'absolute',
		scale: 0.5,
	},
};

const ServiceTeaserContent = ({data}) => {
	const [filterOnePaths, setFilterOnePaths] = useState([]);
	const [filterTwoPaths, setFilterTwoPaths] = useState([]);

	console.log(data);

	// const handleReset = () => {
	// 	setFilterOnePaths([]);
	// 	setFilterTwoPaths([]);
	// };

	const activeItemsFilter = (allPaths) => {
		return data.map((e) => ({
			node: {
				headline: e.node.acf.headline,
				subheadline: e.node.acf.subheadline,
				title: e.node.title,
				path: e.node.path,
				featured_media: e.node.featured_media,
				active: allPaths !== null ? allPaths.indexOf(e.node.path) > -1 : false,
			},
		}));
	};

	const memolizedValue = useMemo(() => {
		const allPaths = [...filterOnePaths, ...filterTwoPaths];

		const items = activeItemsFilter(allPaths);

		return items;
	}, [filterOnePaths, filterTwoPaths]);

	return (
		<>
			<Headline>
				Finde hier den passenden <br />
				360° Service für dich:
			</Headline>
			<Row>
				<FlexColumn xxs={6}>
					<TextWrapper>
						<Title content="Frage 1" />
						<Paragraph content="Möchtest du stöbern oder dich gezielt informieren?" />
					</TextWrapper>

					<Row>
						{filterOne.map((item) => (
							<FilterButton
								onClick={() => setFilterOnePaths(item.paths === filterOnePaths ? [] : item.paths)}
								isActive={item.paths === filterOnePaths}
								label={item.value}
								key={item.value}
							/>
						))}
					</Row>
				</FlexColumn>

				<FlexColumn xxs={6}>
					<TextWrapper>
						<Title content="Frage 2" />
						<Paragraph content="Möchtest du selbstständig Informationen sammeln oder wünschst du dir einen persönlichen MS-Coach, der dich mit relevanten Informationen versorgt?" />
					</TextWrapper>

					<Row>
						{filterTwo.map((item) => (
							<FilterButton
								onClick={() => setFilterTwoPaths(item.paths === filterTwoPaths ? [] : item.paths)}
								isActive={item.paths === filterTwoPaths}
								label={item.value}
								key={item.value}
							/>
						))}
					</Row>
				</FlexColumn>

				<TeaserSelection active={filterOnePaths.length !== 0 || filterTwoPaths.length !== 0}>
					<TeaserWrapper>
						{memolizedValue?.map(({node: item}, index) => (
							<TeaserCard
								initial="hidden"
								animate={item.active ? 'visible' : 'hidden'}
								transition={{type: 'spring', velocity: 0.5}}
								variants={activeVariants}
								key={item.path}>
								<ServiceTeaser
									position={Number.isNaN(index + 1) ? 0 : index + 1}
									item={{
										appearance: 'shadow',
										label: item?.acf?.topline,
										title: item?.headline ? item.headline : item.title,
										subtitle: item?.subheadline,
										image: {
											localFile: {
												publicURL: item?.featured_media?.localFile
													? item.featured_media.localFile.childImageSharp.fluid
													: undefined,
											},
										},
										link: {
											url: `/${item.path}`,
										},
									}}
								/>
							</TeaserCard>
						))}
					</TeaserWrapper>
				</TeaserSelection>
				<TeaserWrapper marginTop="100px" marginBottom="100px" center>
					{memolizedValue?.map(({node: item, index}) => (
						<TeaserCard
							initial="visible"
							animate={!item.active ? 'visible' : 'hidden'}
							transition={{type: 'spring', velocity: 0.5}}
							variants={passiveVariants}
							key={item.path}>
							<ServiceTeaser
								position={Number.isNaN(index + 1) ? 0 : index + 1}
								item={{
									appearance: 'shadow',
									label: item?.acf?.topline,
									title: item?.headline ? item.headline : item.title,
									subtitle: item?.subheadline,
									image: {
										localFile: {
											publicURL: item?.featured_media?.localFile
												? item.featured_media.localFile.childImageSharp.fluid
												: undefined,
										},
									},
									link: {
										url: `/${item.path}`,
									},
								}}
							/>
						</TeaserCard>
					))}
				</TeaserWrapper>
			</Row>
		</>
	);
};

export default ServiceTeaserContent;

ServiceTeaserContent.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
