import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';

import {Wrapper, StyledImage, StyledText} from './styles';

const WpIconText = (props) => {
	const {className, content} = props;
	return (
		<>
			{content?.icontextlist?.map((item) => {
				console.log('item: ', item);
				return (
					<Wrapper key={uuidv4()}>
						<StyledImage src={item.icon?.localFile?.childImageSharp?.fluid} maxWidth="80" type="content" />
						<StyledText className={className} content={item.text} />
					</Wrapper>
				);
			})}
		</>
	);
};

WpIconText.propTypes = {
	/** Needed to overwrite styling via styled components. */
	className: PropTypes.string,
	content: PropTypes.shape({
		icontextlist: PropTypes.arrayOf.isRequired,
	}).isRequired,
};

WpIconText.defaultProps = {
	className: 'ap-icon-text',
};

/** @component */
export default WpIconText;
