import React from 'react';
import PropTypes from 'prop-types';

import {CardStyled} from './styles';

const Card = ({className, children}) => {
	return (
		<CardStyled className={className}>
			{children}
		</CardStyled>
	);
};

Card.propTypes = {
	className: PropTypes.string,
};

Card.defaultProps = {
	className: 'ap-card',
};

/** @component */
export default Card;
