import React from 'react';
import PropTypes from 'prop-types';
import Quote from '../../Elements/Quote/Quote';

const WpQuote = props => {
	const {className, content} = props;
	return <Quote className={className} content={content.content} />;
};

WpQuote.propTypes = {
	/** Needed to overwrite styling via styled components. */
	className: PropTypes.string,
	content: PropTypes.shape({
		content: PropTypes.string.isRequired
	}).isRequired
};

WpQuote.defaultProps = {
	className: 'ap-quote'
};

/** @component */
export default WpQuote;
