import styled from 'styled-components';
import {withUnit, media, gradientService} from '../../../styles/utils';
import {sizes, colors, alertColors} from '../../../styles/variables';
import Text from '../../Elements/Text/Text';
import {Icon} from '../../Elements/Icon/Icon';
import IconButton from '../../Elements/IconButton/IconButton';

export const IconButtonStyled = styled(IconButton)`
	background: transparent;
	position: absolute;
	right: 1em;
	top: 50%;
	transform: translateY(-50%);

	.ap-icon {
		font-size: 1em;
	}
`;

export const TherapyVerficationStyled = styled.div`
	${gradientService()};
	padding: ${withUnit(sizes.distance.base)};

	${media.lg`
		display: flex;
		align-items: center;
		padding: ${withUnit(sizes.distance.base)};
	`};
`;

export const FormStyled = styled.div`
	display: flex;
	margin-top: 1em;

	form {
		position: relative;
		box-sizing: border-box;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;

		${media.xxl`
			flex-direction: row;
			align-items: center;
		`};
	}

	input {
		box-sizing: border-box;
		width: 100%;
		height: ${sizes.forms.inputHeight};
		padding: 0 3em 0 1em;
		border-radius: ${sizes.borderRadius};
		background-color: ${colors.snow};
		border-width: 2px;
		border-style: solid;
		outline: none;
		appearance: none;
		flex-grow: 1;
		border-color: ${(props) => (props.hasError ? alertColors.error.full : `${colors.snow}`)};
		::placeholder {
			color: ${(props) => (props.hasError ? alertColors.error.full : `${colors.black}`)};
		}
		text-align: ${(props) => (props.align === 'rtl' ? 'right' : ``)};
		direction: ${(props) => (props.align === 'rtl' ? 'rtl' : `ltr`)};
	}

	button {
		flex-grow: 0;
		flex-shrink: 0;
		margin-left: 0;
		margin-right: 0;

		${media.xxl`
			margin-left: 1em;
		`};
	}
`;

export const IconWrapper = styled.div`
	${media.lg`
		padding-right: ${withUnit(sizes.distance.base)};
	`};
`;

export const ContentWrapper = styled.div`
	width: 100%;
`;

export const TextStyled = styled(Text)`
	margin-bottom: 0;
	color: ${colors.white};
`;

export const IconStyled = styled(Icon)`
	display: block;
	width: 250px;
	height: 250px;
	margin: 0 auto;

	${media.lg`
		width: 200px;
		height: 200px;
	`};

	${media.xl`
		width: 250px;
		height: 250px;
	`};

	${media.xxl`
		width: 300px;
		height: 300px;
	`};

	svg {
		fill: ${colors.white};
		width: 250px;
		height: 250px;

		${media.lg`
			width: 200px;
			height: 200px;
		`};

		${media.xl`
			width: 250px;
			height: 250px;
		`};

		${media.xxl`
			width: 300px;
			height: 300px;
		`};
	}
`;
