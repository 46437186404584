import styled from 'styled-components';
import {colors, sizes} from '../../../styles/variables';
import {gradientCandyCarrot, media, withUnit} from '../../../styles/utils';
import LogoCleo from '../../Layout/Logo/LogoCleo';
import Text from '../../Elements/Text/Text';

const GradientBox = styled.div`
	background: ${gradientCandyCarrot()};
	padding: 1em;
`;

const GreyBox = styled.div`
	background: ${colors.snow};
	padding: 1em;
`;

const CleoLogo = styled(LogoCleo)`
	display: block;
	color: ${colors.white};
	align-items: center;
	margin: ${withUnit(sizes.distance.base)};

	${media.md`
		display: flex;
	`};
`;

const List = styled.ul`
	list-style: disc;
	padding: 0 1rem;
`;

const ListElement = styled.li`
	color: white;
	font-size: 1em;
`;

const Row = styled.div`
	display: flex;
`;

const BreakRow = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 0.5rem;

	${media.xxl`
	flex-direction: row;
	`};
`;

const Iconbox = styled.div`
	width: 5rem;
	height: 5rem;
	margin-right: 1rem;

	a {
		margin: 0.5rem;
		height: 4rem;
	}
`;

const Textblock = styled(Text)`
	margin-bottom: 0;
	p {
		margin-bottom: 0;
	}
`;

const Image = styled.div`
	background: url(${(props) => props.src});
	width: 5rem;
	height: 5rem;
	margin-right: 1rem;
	background-size: contain;
	background-repeat: no-repeat;
`;

const StoreImage = styled.div`
	background: url(${(props) => props.src});
	width: 10rem;
	height: 5rem;
	margin-right: 0.5rem;
	background-size: contain;
	background-repeat: no-repeat;
`;

export const Styled = {
	GradientBox,
	BreakRow,
	StoreImage,
	GreyBox,
	CleoLogo,
	List,
	ListElement,
	Textblock,
	Iconbox,
	Image,
	Row,
};
