import React from 'react';
import PropTypes from 'prop-types';
import {StyledFilterButton, Line} from './styles';

const FilterButton = ({onClick, label, isActive, showLine, className}) => {
	return (
		<StyledFilterButton isActive={isActive} onClick={onClick} className={className}>
			{label}
			<Line isActive={showLine && isActive} />
		</StyledFilterButton>
	);
};

FilterButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	showLine: PropTypes.bool,
};

FilterButton.defaultProps = {
	className: 'ap-filterbutton',
	showLine: false,
};

/** @component */
export default FilterButton;
