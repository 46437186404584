import React, {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {navigate} from '@reach/router';
import {FormStyled, IconButtonStyled} from '../styles';
import {setPZN} from '../../../../state/actions/userManagement';
import {Icon} from '../../../Elements/Icon/Icon';

const TherapyVerificator = (props) => {
	const {handlePZN, value, lang, ar, chapter} = props;
	const [pzn, setLocalPzn] = useState('');
	const [error, setError] = useState(false);

	let product = '';
	let productName = '';
	let productNode = {};

	let placeholder = '';
	let direction = 'ltr';
	switch (lang) {
		case 'en':
			placeholder = 'Enter PZN code';
			break;
		case 'tr':
			placeholder = 'PZN‘yi giriniz';
			break;
		case 'ru':
			placeholder = 'Ввести PZN';
			break;
		case 'ar':
			placeholder = 'ادخل الرقم الدوائي المركزي PZN';
			direction = 'rtl';
			break;
		default:
			placeholder = 'PZN eingeben';
	}

	const data = useStaticQuery(graphql`
		{
			allWordpressPage {
				edges {
					node {
						template
						wordpress_id
						slug
						title
					}
				}
			}
		}
	`);

	const filteredData = data.allWordpressPage.edges.filter((el) => el.node.template === 'template-wirkstoff.php');

	const submitData = async (newValue = pzn) => {
		if (newValue.match(/^[0-9]*$/gm) && newValue.trim() !== '') {
			product = await fetch(`${process.env.GATSBY_PZN_API}/${newValue}`)
				.then((response) => {
					if (!response.ok) {
						window.dataLayer.push({
							event: 'biib_loginError',
							eventAsset: 'login to closed therapy area - without success',
							eventAssetBis: `${process.env.GATSBY_PZN_API}/${newValue}`,
						});
						console.log('login error');
						console.log('window', window);
						setError(true);
						throw response;
					}
					return response.json();
				})
				.then((res) => parseInt(res, 10));
			// .then((res) => {
			// 	window.dataLayer.push({
			// 		event: 'biib_loginSuccess',
			// 		eventAsset: 'login to closed therapy area - success',
			// 		eventAssetBis: `${process.env.GATSBY_PZN_API}/${newValue}`,
			// 	});
			// 	return res;
			// });

			productNode = await filteredData.filter((el) => el.node.wordpress_id === product);

			if (productNode && productNode[0]) {
				switch (productNode[0].node.slug) {
					case 'fampridin':
						productName = 'fampyra';
						break;
					case 'natalizumab':
						productName = 'tysabri';
						break;
					case 'peginterferon-beta-1a-s-c':
						productName = 'plegridy';
						break;
					case 'dimethylfumarat':
						productName = 'tecfidera';
						break;
					case 'interferon-beta-1a-i-m':
						productName = 'avonex';
						break;
					case 'diroximelfumarat':
						productName = 'vumerity';
						break;
					default:
						break;
				}
				handlePZN({
					name: productNode[0].node.title,
					slug: productNode[0].node.slug,
					product: productName,
					lang,
					ar,
					chapter,
				});
			} else {
				setError(true);
			}
		} else {
			window.dataLayer.push({
				event: 'biib_loginError',
				eventAsset: 'login to closed therapy area - without success',
				eventAssetBis: `${process.env.GATSBY_PZN_API}/${newValue}`,
			});
			setError(true);
		}
	};

	const unsetError = () => {
		setError(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		submitData();
		setLocalPzn('');
	};

	useEffect(() => {
		setLocalPzn(value);

		if (document.getElementById('pznFormInput').value !== 'undefined' && value !== undefined) {
			setTimeout(() => {
				const token = document.getElementById('pznFormInput').value;
				submitData(token);
			}, 2);
		}
	}, [value]);

	useEffect(() => {
		if (error && document.location.pathname.includes('therapieinformationen')) {
			navigate('/');
		}
	}, [error]);

	return (
		<FormStyled hasError={error} align={direction}>
			<form onSubmit={handleSubmit} name="pznFormSubmit">
				<input
					id="pznFormInput"
					type="text"
					value={pzn}
					onClick={unsetError}
					onKeyPress={unsetError}
					onChange={(e) => setLocalPzn(e.target.value)}
					placeholder={
						error ? 'Die eingegebene PZN entspricht keinem der oben genannten Wirkstoffe.' : placeholder
					}
				/>
				<IconButtonStyled type="submit" theme="white">
					<Icon name="logout" />
				</IconButtonStyled>
			</form>
		</FormStyled>
	);
};

const mapDispatchToProps = (dispatch) => ({
	handlePZN: (pzn) => dispatch(setPZN(pzn)),
});

TherapyVerificator.propTypes = {
	handlePZN: PropTypes.func,
	value: PropTypes.string,
	chapter: PropTypes.string,
	lang: PropTypes.string,
	ar: PropTypes.string,
};

TherapyVerificator.defaultProps = {
	handlePZN: null,
	value: undefined,
	chapter: undefined,
	lang: undefined,
	ar: undefined,
};

export default connect(null, mapDispatchToProps)(TherapyVerificator);
