import styled from 'styled-components';
import {motion} from 'framer-motion';

import {colors} from '../../../styles/variables';
import {media} from '../../../styles/utils';

export const TabsStyled = styled.div`
	max-width: 100%;
`;

export const TabList = styled.div`
	border: 2px solid ${colors.carrot};
	border-radius: 50px;
	overflow: hidden;
	position: relative;

	&::before,
	&::after {
		content: '';
		pointer-events: none;
		position: absolute;
		height: 100%;
		width: 2.5rem;
		top: 0;

		${media.xl`
			display: none;
		`};
	}

	&::before {
		left: 0;
		z-index: 5;
		background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	}

	&::after {
		right: 0;
		background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	}
`;

export const ScrollWrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow-x: scroll;
	position: relative;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display: none;
	}
`;

export const TabsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	position: relative;
	height: 100%;
	min-width: 45rem;
	width: 100%;
`;

export const Tab = styled.div`
	position: relative;
	cursor: pointer;
	padding: 0.5rem 1rem;
	text-align: center;
	color: ${(props) => (props.active ? colors.white : '')};
	width: ${(props) => props.width};
	z-index: ${(props) => (props.active ? 10 : 0)};
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`;

export const TabContent = styled.div`
	margin-top: 2rem;
`;

export const Gradient = styled(motion.div)`
	background: ${colors.gradient};
	border-radius: 50px;
	left: 0;
	top: 0;
	width: ${(props) => props.width};
	height: 100%;
	position: absolute;
	z-index: 9;
`;
