import React from 'react';
import PropTypes from 'prop-types';

const WpTabAnchorLink = (props) => {
	const {className, content} = props;
	const url = new URL(content.page_link.url);
	const {pathname} = url;
	return (
		<div className={className}>
			<a href={`${pathname}?tabId=${content.tab_name}&anchorId=${content.anchor_id}`} rel="noopener noreferrer">
				{content.anchor_text}
			</a>
		</div>
	);
};

WpTabAnchorLink.propTypes = {
	className: PropTypes.string,
	content: PropTypes.shape({
		page_link: PropTypes.string.isRequired,
		tab_name: PropTypes.string.isRequired,
		anchor_text: PropTypes.string.isRequired,
		anchor_id: PropTypes.string.isRequired,
	}).isRequired,
};

WpTabAnchorLink.defaultProps = {
	className: 'ap-anchor-tab-link',
};

export default WpTabAnchorLink;
