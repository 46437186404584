import React from 'react';
import {v1 as uuidv1} from 'uuid';
import PropTypes from 'prop-types';
import WpText from '../WpText/WpText';
import WpHeadline from '../WpHeadline/WpHeadline';
import WpInfobox from '../WpInfobox/WpInfobox';
import WpImage from '../WpImage/WpImage';
import WpList from '../WpList/WpList';
import WpButton from '../WpButton/WpButton';
import WpQuote from '../WpQuote/WpQuote';
import WpSpotify from '../WpSpotify/WpSpotify';
import WpVideo from '../WpVideo/WpVideo';
import WpIntro from '../WpIntro/WpIntro';
import WpTeaserGroup from '../WpTeaserGroup/WpTeaserGroup';
import Accordion from '../../Elements/Accordion/Accordion';

const reactComponents = {
	headline: WpHeadline,
	text: WpText,
	intro: WpIntro,
	infobox: WpInfobox,
	image: WpImage,
	list: WpList,
	button: WpButton,
	quote: WpQuote,
	spotify: WpSpotify,
	teasergroup: WpTeaserGroup,
	video: WpVideo,
};

const WpAccordion = (props) => {
	const {
		content: {items, stil},
	} = props;
	const itemArray = [];

	for (let i = 0; i < items.length; i += 1) {
		const contentElements = items[i].content.content_elements;
		const renderComponents = contentElements.map((elem) => {
			const Component = reactComponents[elem.acf_fc_layout];
			return <Component key={uuidv1()} content={elem} />;
		});
		const entryObject = {
			title: items[i].title,
			content: renderComponents,
		};
		itemArray.push(entryObject);
	}
	return <Accordion data={itemArray} style={stil} />;
};

export default WpAccordion;

WpAccordion.defaultProps = {
	content: {
		items: [],
		stil: '',
	},
};

WpAccordion.propTypes = {
	content: PropTypes.shape({
		content_elements: PropTypes.array,
		items: PropTypes.arrayOf(PropTypes.object),
		stil: PropTypes.string,
	}),
};
