import React, {useState} from 'react';

import Text from 'components/Elements/Text/Text';
import Link from 'components/Elements/Link/Link';

import {
	UserQueryStyled,
	QuestionBlock,
	Title,
	ErrorBlock,
	SuccessBlock,
	Container,
	ButtonContainer,
	StyledFilterButton,
} from './styles';
import {questions, lang} from './queryData';

const UserQuery = () => {
	const [selectedAnswers, setSelectedAnswers] = useState([]);

	const setAnswer = (questionIndex, answerIndex) => {
		const selected = [...selectedAnswers];

		// set or remove answer if already set
		selected[questionIndex] = selected[questionIndex] === answerIndex ? undefined : answerIndex;

		// cut array after given answer
		selected.length = questionIndex + 1;

		setSelectedAnswers(selected);
	};

	const checkRequiredAnswer = (questionIndex) => {
		return selectedAnswers[questionIndex] === questions[questionIndex].requiredAnswer;
	};

	const isActive = (questionIndex) => {
		return questionIndex > 0 ? checkRequiredAnswer(questionIndex - 1) : true;
	};

	const isError = (questionIndex) => {
		return selectedAnswers[questionIndex] !== undefined ? !checkRequiredAnswer(questionIndex) : false;
	};

	return (
		<UserQueryStyled>
			{questions.map((item, questionIndex) => {
				return (
					<QuestionBlock key={`Question ${questionIndex + 1}`} isActive={isActive(questionIndex)}>
						<Title content={`Frage ${questionIndex + 1}`} />
						<Text content={item.question} styles={{margin: '0'}} />

						<Container>
							<ButtonContainer>
								{item.answers.map((answer, answerIndex) => {
									return (
										<StyledFilterButton
											key={`Answer ${answerIndex + 1}`}
											label={answer}
											onClick={() => setAnswer(questionIndex, answerIndex)}
											isActive={selectedAnswers[questionIndex] === answerIndex}
										/>
									);
								})}
							</ButtonContainer>

							<ErrorBlock isVisible={isError(questionIndex)}>
								<Text content={lang.errorText} styles={{margin: '0'}} />
							</ErrorBlock>
						</Container>
					</QuestionBlock>
				);
			})}
			<SuccessBlock isVisible={checkRequiredAnswer(questions.length - 1)}>
				<Text content={lang.succesText} styles={{color: 'white', margin: '0 0 1rem 0'}} />
				<Link to={lang.successLink}>{lang.successLink}</Link>
			</SuccessBlock>
		</UserQueryStyled>
	);
};

/** @component */
export default UserQuery;
