import React from 'react';
import PropTypes from 'prop-types';
import {v1 as uuidv1} from 'uuid';
import {UnorderedListStyled, OrderedListStyled, TitleStyled, ContentStyled} from './styles';

const List = (props) => {
	const {items, as, type} = props;
	if (items.length === 0) {
		return null;
	}

	// Gather items
	const listItems = items.map((item) => {
		const beforeParse = item.text;

		let afterParse = null;

		const regex1 = /&lt;&lt;/gi;
		const regex2 = /&gt;&gt;/gi;

		afterParse = beforeParse.replace(regex1, '<div dir="rtl">');
		afterParse = afterParse.replace(regex2, '</div>');

		if (item.children) {
			return (
				<li key={uuidv1()}>
					{item.title && <TitleStyled>{item.title}</TitleStyled>}
					{item.children && <ContentStyled>{item.children}</ContentStyled>}
				</li>
			);
		}
		return (
			<li key={uuidv1()}>
				{item.title && <TitleStyled>{item.title}</TitleStyled>}
				{item.text && <ContentStyled dangerouslySetInnerHTML={{__html: afterParse}} />}
			</li>
		);
	});

	// Type: ordered list
	if (as === 'ol') {
		return <OrderedListStyled type={type}>{listItems}</OrderedListStyled>;
	}

	// Type: unordered list
	return <UnorderedListStyled type={type}>{listItems}</UnorderedListStyled>;
};

List.propTypes = {
	as: PropTypes.oneOf(['ul', 'ol']),
	type: PropTypes.oneOf(['decimal', 'upper-alpha']),
	items: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			title: PropTypes.string,
			text: PropTypes.string,
		})
	).isRequired,
};

List.defaultProps = {
	as: 'ul',
	type: 'decimal',
};

/** @component */
export default List;
