import React from 'react';
import PropTypes from 'prop-types';
import Infobox from '../../Elements/Infobox/Infobox';
import {themes} from '../../Elements/Infobox/styles';

const WpInfobox = ({content}) => {
	return <Infobox text={content.content} theme={content.theme} />;
};

WpInfobox.propTypes = {
	/** Needed to overwrite styling via styled components. */
	content: PropTypes.shape({
		content: PropTypes.string.isRequired,
		theme: PropTypes.oneOf(themes)
	}).isRequired
};

/** @component */
export default WpInfobox;
