import React from 'react';
import PropTypes from 'prop-types';
import ReleaseNumbers from '../../Elements/ReleaseNumber/ReleaseNumbers';

const WpReleaseNumbers = ({content}) => {
	if (!content.content) {
		return null;
	}
	return <ReleaseNumbers releaseNumbers={content.content} />;
};

WpReleaseNumbers.propTypes = {
	content: PropTypes.shape({
		content: PropTypes.string
	}).isRequired
};

WpReleaseNumbers.defaultProps = {};

/** @component */
export default WpReleaseNumbers;
