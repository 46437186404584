import React from 'react';
import PropTypes from 'prop-types';
import {withSize} from 'react-sizeme';

import {IconInfoboxStyled, IconWrapper, IconStyled, ContentWrapper, HeadlineStyled} from './styles';

const IconInfobox = ({theme, iconName, iconType, title, children, size, boxType, direction = 'lrt'}) => {
	return (
		<IconInfoboxStyled theme={theme} size={size} boxType={boxType}>
			<IconWrapper size={size} boxType={boxType} theme={theme}>
				<IconStyled name={iconName} type={iconType} theme={theme} />
			</IconWrapper>
			<ContentWrapper size={size} theme={theme}>
				<HeadlineStyled as="h2" align={direction} dangerouslySetInnerHTML={{__html: title}} />
				{children}
			</ContentWrapper>
		</IconInfoboxStyled>
	);
};

IconInfobox.propTypes = {
	theme: PropTypes.oneOf(['service', 'info', 'therapy']),
	iconName: PropTypes.string,
	iconType: PropTypes.oneOf(['teaser', 'category', 'default']),
	title: PropTypes.string,
	children: PropTypes.node,
	boxType: PropTypes.string,
	size: PropTypes.shape({width: PropTypes.number, height: PropTypes.number}),
};

IconInfobox.defaultProps = {
	theme: 'info',
	iconName: null,
	iconType: 'default',
	title: null,
	children: null,
	boxType: null,
	size: null,
};

export default withSize({noPlaceholder: true})(IconInfobox);
