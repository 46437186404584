import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../Elements/Text/Text';

const WpText = (props) => {
	const {className, content} = props;

	return <Text className={className} content={content.content} />;
};

WpText.propTypes = {
	/** Needed to overwrite styling via styled components. */
	className: PropTypes.string,
	content: PropTypes.shape({
		content: PropTypes.string.isRequired,
	}).isRequired,
};

WpText.defaultProps = {
	className: 'ap-text',
};

/** @component */
export default WpText;
