import React from 'react';
import star from '../../../assets/img/cleo/Star_orange.svg';

import {Styled} from './styles';

export const CleoReview2 = () => (
	<>
		<Styled.Block>
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
		</Styled.Block>

		<Styled.InlineText>Schöne App</Styled.InlineText>
		<Styled.Text>„Sehr übersichtlich aufgebaut, guter Funktionsumfang, ist sicher eine große Hilfe.“</Styled.Text>

		<Styled.Spacer />
	</>
);
