import React from 'react';
import TherapyVerificator from './TherapyVerificator/TherapyVerificator';
import {ContentWrapper, TextStyled} from './styles';

export const TherapyInformation = ({lang}) => {
	let lead = '';
	let direction = 'ltr';

	switch (lang) {
		case 'en':
			lead =
				"To access extensive treatment information, you need to enter your medicine's central pharmaceutical information number (PZN code). The PZN code is an eight-digit number which you will find on the box.";
			break;
		case 'tr':
			lead =
				'Tedavi hakkında kapsamlı bilgilere ulaşabilmeniz için ilacınızın merkezî ecza kodunu (PZN) girmeniz gerekiyor. PZN, ambalaj üzerinde bulabileceğiniz sekiz haneli bir numaradır.';
			break;
		case 'ru':
			lead =
				'Чтобы получить доступ к подробной информации о терапии, нужно ввести центральный фармацевтический номер (PZN) Вашего медикамента. PZN представляет собой восьмизначный номер, который Вы найдете на упаковке.';
			break;
		case 'ar':
			lead =
				'من أجل التوصل إلى معلومات علاجية شاملة، يلزم الامر ادخال الرقم الدوائي المركزي (PZN) للدواء الذي تستخدمه. يتكون الرقم من ثماني خانات .';
			direction = 'rtl';
			break;
		default:
			lead =
				'Gib die achtstellige Pharmazentralnummer (PZN) hier ein – und du hast Zugang zu zahlreichen Informationen zu deiner Biogen MS-Behandlung.';
	}

	return (
		<ContentWrapper>
			<TextStyled content={lead} direction={direction} />
			<TherapyVerificator lang={lang} />
		</ContentWrapper>
	);
};
