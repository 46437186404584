import styled, {css} from 'styled-components';
import {colors, sizes} from '../../../../styles/variables';
import {withUnit, media} from '../../../../styles/utils';
import Image from '../../Image/Image';

export const appearances = ['default', 'shadow', 'relatedContent'];

const TeaserShadowStyles = css`
	box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);

	${media.lg`
		box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
	`};
`;

const TeaserImageStyles = css`
	box-sizing: border-box;
	position: absolute;
	top: 2rem;
	left: 50%;
	transform: translateX(-50%) scale(1);
	height: 6rem;
	width: 6rem;
	transition: transform 0.5s ease;
	transform-origin: 50% 50%;

	img {
		position: absolute;
		left: 50% !important;
		top: 50% !important;
		transform: translate(-50%, -50%) !important;
		object-fit: contain !important;
		min-width: 30%;
	}
`;

export const ServiceTeaserImageStyled = styled(Image)`
	${TeaserImageStyles};

	background-image: none !important;

	&::after {
		display: none !important;
	}
`;

export const TeaserImageStyled = styled(Image)`
	${TeaserImageStyles};
`;

export const TeaserCard = styled.div`
	position: relative;
	width: 18rem;
	height: 18rem;
	margin: 0.5rem;
	border-radius: 30px;
	overflow: hidden;

	${TeaserShadowStyles};
`;

export const TeaserStyled = styled.a`
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	box-sizing: border-box;
	background-color: ${colors.white};
	width: 100%;
	height: 100%;
	text-decoration: none;
	color: ${colors.black};
	overflow: hidden;

	${media.md`
		&:hover {
			${TeaserImageStyled},
			${ServiceTeaserImageStyled} {
				transform: translateX(-50%) scale(0.8);
			}
		}
	`};
`;

export const ServiceTeaserSubtitleStyled = styled.div`
	text-align: center;
	font-size: 0.8em;
	font-weight: 400;
	margin-top: 0.5em;
	transition: font-size 0.3s ease-out, opacity 0.3s ease-out;
	color: ${colors.black};

	strong,
	a {
		display: inline-block;
	}
`;

const TeaserTitleStyles = css`
	text-align: center;
	font-size: 0.8em;
	width: 100%;
	margin-bottom: 0;

	${() => TeaserStyled}:hover & {
		color: ${colors.black};
	}
`;

export const ServiceTeaserTitleStyled = styled.div`
	${TeaserTitleStyles};
`;
export const TeaserTitleStyled = styled.div`
	${TeaserTitleStyles};

	${() => TeaserStyled}:hover & {
		color: ${colors.white};
	}
`;

export const ServiceTeaserTextStyles = css`
	margin-top: ${withUnit(sizes.distance.base / 2)};
`;

export const ServiceTeaserTextStyled = styled.div`
	${ServiceTeaserTextStyles};
`;

export const TeaserTextStyled = styled.div`
	${ServiceTeaserTextStyles};
`;

const TeaserContentStyles = css`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 101%;
	height: 100px;
	box-sizing: border-box;
	padding: ${withUnit(sizes.distance.base / 4)};
	z-index: 1;

	${media.sm`
		padding: ${withUnit(sizes.distance.base / 2)};
		
	`}

	${media.md`
		height: unset;
		padding: ${withUnit(sizes.distance.base)};
	`};
`;

export const ServiceTeaserContentStyled = styled.div`
	${TeaserContentStyles};
	height: 50%;
	padding: ${withUnit(sizes.distance.base / 3)};
`;

export const TeaserContentStyled = styled.div`
	${TeaserContentStyles};

	${() => TeaserStyled}:hover &::before {
		transform: translate(-50%, 100%);
	}
`;
