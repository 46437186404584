import styled from 'styled-components';
import {colors, sizes} from '../../../styles/variables';
import {withUnit} from '../../../styles/utils';
import {GlobalListStyle} from '../../../styles/global';

export const UnorderedListStyled = styled.ul`
	${GlobalListStyle};
`;

export const OrderedListStyled = styled.ol`
	counter-reset: list-ordered;
	font-weight: 300;
	font-size: 1em;
	margin-bottom: 1.5em;
	margin-left: 0;
	padding-left: 0;
	list-style: none;

	li {
		counter-increment: list-ordered;
		position: relative;
		padding-left: ${withUnit(sizes.distance.base * 1.4)};
		margin-bottom: ${withUnit(sizes.distance.base / 3)};

		&::before {
			position: absolute;
			flex: none;
			content: counter(list-ordered, ${props => (props.type ? props.type : 'decimal')});
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: ${colors.carrot};
			color: ${colors.white};
			font-weight: 700;
			font-size: 0.75em;
			border-radius: 50%;
			width: ${withUnit(sizes.distance.base)};
			height: ${withUnit(sizes.distance.base)};
			line-height: ${withUnit(sizes.distance.base)};
			left: 0;
			top: 1px;
		}
	}
`;

export const TitleStyled = styled.strong`
	display: block;
`;

export const ContentStyled = styled.div`
	display: block;

	p {
		margin-top: 0;
	}
`;
