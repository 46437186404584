import React from 'react';
import PropTypes from 'prop-types';
import Slider from '../../Container/Slider/Slider';
import Video from '../../Elements/Video/Video';
import Image from '../../Elements/Image/Image';

import {SliderWrapper, SliderItem} from './styles';

const WpSlider = (props) => {
	const {content} = props;

	const images = Object.keys(content)
		.filter((key) => key.includes('image') && content[key] !== null)
		.map((key) => {
			return (
				<SliderItem key={key}>
					<Image src={content[key].localFile.childImageSharp.fluid} />
				</SliderItem>
			);
		});

	const videos = Object.keys(content)
		.filter((key) => key.includes('video') && content[key] !== null)
		.map((key) => {
			return (
				<SliderItem key={key}>
					<Video content={{caption: content[key], videoEmbed: content[key]}} withCaption={false} />
				</SliderItem>
			);
		});

	return (
		<SliderWrapper singleItem={images.length + videos.length === 1}>
			<Slider maxShow={1}>
				{videos}
				{images}
			</Slider>
		</SliderWrapper>
	);
};

WpSlider.propTypes = {
	content: PropTypes.shape({}),
};

WpSlider.defaultProps = {
	content: null,
};

/** @component */
export default WpSlider;
