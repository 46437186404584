import React from 'react';
import facebook from '../../../assets/img/cleo/facebook.png';
import Button from '../../Elements/Button/Button';

import {Styled} from './styles';

export const CleoFace = () => (
	<>
		<Styled.Block flex>
			<Styled.Image src={facebook} alt="Bild eines Fertigpens" />
			<Styled.InlineText thin>
				Übrigens: Cleo hat auch einen Facebook-Account:
				<br /> <Styled.Bold>Cleo Meine MS-App</Styled.Bold>
			</Styled.InlineText>
		</Styled.Block>

		<Button to="https://de-de.facebook.com/cleoappms/" text="Zu Cleo Facebook" type="link" size="small" />
		<Styled.Spacer />
	</>
);
