import styled from 'styled-components';
import {colors, alertColors, sizes} from '../../../styles/variables';
import Text from '../../Elements/Text/Text';
import IconButton from '../../Elements/IconButton/IconButton';

export const FormStyled = styled.form`
	position: relative;

	input {
		box-sizing: border-box;
		width: 100%;
		height: ${sizes.forms.inputHeight};
		padding: 0 3em 0 1em;
		border-radius: ${sizes.borderRadius};
		background-color: ${colors.snow};
		border-width: 2px;
		border-style: solid;
		outline: none;
		appearance: none;
		border-color: ${props => (props.hasError ? alertColors.error.full : `${colors.snow}`)};
		::placeholder {
			color: ${props => (props.hasError ? alertColors.error.full : `${colors.black}`)};
		}
	}
`;

export const TextStyled = styled(Text)`
	color: ${colors.white};
`;

export const IconButtonStyled = styled(IconButton)`
	background: transparent;
	position: absolute;
	right: 1em;
	top: 50%;
	transform: translateY(-50%);

	.ap-icon {
		font-size: 1em;
	}
`;
