import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../Elements/Image/Image';
import {appearances, alignments} from '../../Elements/Image/styles';

const WpImage = ({content}) => {
	if (content.image && content.image.localFile) {
		return (
			<Image
				src={content.image.localFile.childImageSharp.fluid}
				alt={content.image.alt_text ? content.image.alt_text : ''}
				title={content.image.title}
				appearance={content.appearance}
				maxWidth={content.max_width}
				type="content"
				alignment={content.alignment}
				caption={content.caption}
				optionalLink={content.optional_link}
			/>
		);
	}
	// remote image from protected wp-content
	if (content.image && content.image.url) {
		return (
			<Image
				src={content.image.url}
				alt={content.image.alt_text ? content.image.alt_text : ''}
				title={content.image.title}
				appearance={content.appearance}
				maxWidth={content.max_width}
				type="content"
				alignment={content.alignment}
				caption={content.caption}
				optionalLink={content.optional_link}
			/>
		);
	}
	return null;
};

WpImage.propTypes = {
	content: PropTypes.shape({
		caption: PropTypes.string,
		image: PropTypes.shape({
			alt_text: PropTypes.string,
			title: PropTypes.string,
			localFile: PropTypes.shape({
				childImageSharp: PropTypes.shape({
					fluid: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
					original: PropTypes.shape({
						src: PropTypes.string.isRequired,
					}),
				}),
			}),
		}),
		max_width: PropTypes.string,
		appearance: PropTypes.oneOf(appearances),
		alignment: PropTypes.oneOf(alignments),
	}).isRequired,
};

/** @component */
export default WpImage;
