export const questions = [
	{question: 'Bist du Angehöriger oder Patient?', answers: ['Angehöriger', 'Patient'], requiredAnswer: 1},
	{
		question: 'Bist du derzeit auf einer Therapie von Biogen<sup>&reg;</sup>?',
		answers: ['Ja', 'Nein'],
		requiredAnswer: 0,
	},
];

export const lang = {
	succesText:
		'Herzlichen Glückwunsch!<br/>Du darfst am Patientenprogramm teilnehmen! Fülle dieses Formular aus und melde dich auf der Seite an.',
	successLink: 'https://mein.ms-life.de/service/gemeinsam-stark/anmeldung/',
	errorText:
		'Du kannst das Forum und die Bookmark-Funktion für Artikel nutzen! In deinem Profil bekommst du außerdem Vorschläge für weitere Artikel die für dich interessant sein könnten.<br/><br/>Die Services des digitalen Begleitprogramms sind aber tatsächlich nur für MS-Patienten, welche eine Biogen-Therapie erhalten, zugänglich.',
};
