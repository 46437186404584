import styled from 'styled-components';
import {withUnit} from '../../../styles/utils';
import {sizes, colors, alertColors} from '../../../styles/variables';

export const AutoEnrollerFormStyled = styled.div`
	max-width: 800px;
	margin: 0 auto;
`;

export const Spacer = styled.div`
	padding: 1em 0;
`;

export const DatepickerStyled = styled.div`
	width: 100%;
	margin: 0;

	span.label {
		display: block;
		font-size: 0.6em;
		padding: 0 ${withUnit(sizes.distance.base)} ${withUnit(sizes.distance.base / 4)}
			${withUnit(sizes.distance.base)};
		text-transform: uppercase;
		font-weight: 800;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}
	.react-datepicker__input-container {
		width: 100%;
	}

	input {
		box-sizing: border-box;
		width: 100%;
		height: ${sizes.forms.inputHeight};
		border-radius: 2.5em;
		background-color: ${colors.snow};
		border-width: 2px;
		border-style: solid;
		border-color: ${colors.snow};
		outline: none;
		padding: 0 ${withUnit(sizes.distance.base)};

		&:focus {
			border: 2px solid ${alertColors.focus.full};
		}
	}

	.hasError input {
		border-color: ${alertColors.error.full};
	}
`;

export const LabelForCheckboxStyled = styled.label`
	display: flex;
	font-size: 1rem;
	font-weight: 300;
	padding: 1em;
	border-width: 2px;
	border-radius: 8px;
	border-color: ${props => (props.hasError ? alertColors.error.full : 'transparent')};
	cursor: pointer;
`;
export const SpanForLabel = styled.span`
	padding-left: 1em;
	text-align: left;
`;

