import React from 'react';
import PropTypes from 'prop-types';
import {ReleaseNumberStyled} from './styles';

const ReleaseNumber = props => {
	const {releaseNumber} = props;
	if (releaseNumber === null || releaseNumber === '') {
		return null;
	}
	return <ReleaseNumberStyled>Biogen-{releaseNumber}</ReleaseNumberStyled>;
};

ReleaseNumber.propTypes = {
	releaseNumber: PropTypes.string
};

ReleaseNumber.defaultProps = {
	releaseNumber: null
};

/** @component */
export default ReleaseNumber;
