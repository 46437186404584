import React from 'react';
import star from '../../../assets/img/cleo/Star_orange.svg';

import {Styled} from './styles';

export const CleoReview3 = () => (
	<>
		<Styled.Block>
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
			<Styled.Icon src={star} alt="Bild eines Fertigpens" />
		</Styled.Block>

		<Styled.InlineText>Coach</Styled.InlineText>
		<Styled.Text>
			„Ich war echt überrascht, dass ich wirklich mit einem Experten zu meinen Fragen direkt chatten kann. Toll!!“
		</Styled.Text>
		<Styled.Spacer />
	</>
);
