import styled from 'styled-components';
import {colors} from '../../../../styles/variables';

export const StyledTeaserSelection = styled.div`
	width: 100%;
	height: ${(props) => props.active ? '' : '0'};
	opacity: ${(props) => props.active ? '1' : '0'};
	border-radius: 30px;
	background: ${colors.gradient};
	padding: ${(props) => props.active ? '1rem 4rem' : '0 4rem'};
	transition: all 500ms;
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
`;

export const Headline = styled.h4`
	color: white;
`;
