import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Elements/Button/Button';

const WpButton = ({content}) => {
	return <Button type="link" to={content.link.url} theme={content.button_theme} direction={content.button_theme} text={content.link.title} />;
};

WpButton.propTypes = {
	content: PropTypes.shape({
		button_theme: PropTypes.string,
		icon: PropTypes.string,
		link: PropTypes.shape({
			title: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
			target: PropTypes.string
		})
	}).isRequired
};

WpButton.defaultProps = {};

/** @component */
export default WpButton;
