import React from 'react';

const EventVideo = () => {
	return (
		<div style={{width: "100%", display: "inline-block", position:"relative"}}>
            <div id="dummy" style={{marginTop: "56.25%"}}></div>
			<iframe
				src="https://vimeo.com/event/984506/embed"
				frameBorder="0"
				allow="autoplay; fullscreen; picture-in-picture"
				allowFullScreen
				style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}></iframe>
		</div>
	);
};

export default EventVideo;
