import React from 'react';
import * as Yup from 'yup';

import BrochureSelection from './BrochureSelection';
import BrochureOrderPersonalData from './BrochureOrderPersonalData';
import {BrochureOrderFormStyled} from './styles';
import SubmitForm from '../SubmitForm/SubmitForm';
import {captchaValidation, emailValidation, nonEmptyStringValidation} from '../../../utils/validations';
import {forms} from '../../../assets/language/de';

const {validationMessages} = forms.BrochureOrder;

const initialValues = {
	captcha: '',
	country: '',
	email: '',
	federalState: '',
	forename: '',
	houseNumber: '',
	phone: '',
	hint1Accepted: false,
	hint2Accepted: false,
	salutation: '',
	street: '',
	surname: ''
};

const validationSchema = Yup.object().shape({
	captcha: captchaValidation(validationMessages.captcha),
	country: nonEmptyStringValidation(validationMessages.country),
	email: emailValidation(validationMessages.email.ifEmpty, validationMessages.email.ifNotValid),
	federalState: nonEmptyStringValidation(validationMessages.federalState),
	forename: nonEmptyStringValidation(validationMessages.forename.ifEmpty),
	hint1Accepted: nonEmptyStringValidation(validationMessages.hint1Accepted),
	hint2Accepted: nonEmptyStringValidation(validationMessages.hint2Accepted),
	houseNumber: nonEmptyStringValidation(validationMessages.houseNumber),
	phone: nonEmptyStringValidation(validationMessages.phone.ifEmpty),
	salutation: nonEmptyStringValidation(validationMessages.salutation),
	street: nonEmptyStringValidation(validationMessages.street),
	surname: nonEmptyStringValidation(validationMessages.surname)
});

/** @component */
const BrochureOrder = () => (
	<>
		<p>
			<em>
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
				labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
				ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
			</em>
		</p>
		<SubmitForm
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={() => console.log('see Contact/Container.js')}
			renderInnerForm={formikProps => (
				<BrochureOrderFormStyled>
					<BrochureSelection {...formikProps} />
					<BrochureOrderPersonalData {...formikProps} />
				</BrochureOrderFormStyled>
			)}
		/>
	</>
);

export default BrochureOrder;
