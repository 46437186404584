import styled from 'styled-components';
import {colors} from '../../../styles/variables';
import {Icon} from '../../Elements/Icon/Icon';

export const ShareBlockStyled = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	p {
		font-size: 0.9rem;
		font-weight: bold;
		padding-right: 12px;
	}
	.react-share__ShareButton:focus {
		outline: none;
	}
	.react-share__ShareButton {
		outline: none;
	}
`;

export const SocialIconStyled = styled(Icon)`


svg {
    fill: ${colors.carrot};
    height: 2rem;
    width: 2rem;

    
;
`;
