import React from 'react';

import {connect} from 'react-redux';
import {submitEnroller} from '../../../state/actions/enroller';

import AutoEnrollerForm from './AutoEnrollerForm';

const AutoEnroller = ({submitEnroller}) => {
	const handleSubmit = (data) => {
		if (data && data.forename) {
			const options = {
				method: 'POST',
				mode: 'cors',
				redirect: 'follow',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: `data=${JSON.stringify(data)}`,
			};

			fetch(`${process.env.GATSBY_UMS_URL}/autoenroller`, options)
				.then((response) => response.json())
				.then(
					// dataLayer.push({
					// 	event: 'biib_formSubmit',
					// 	eventAsset: 'submission of PSP Online Enroller',
					// 	eventAssetBis: '/',
					// }),
					() => submitEnroller() // Handle the success response object
				)
				.catch(
					(error) => console.log(error) // Handle the error response object
				);

			submitEnroller();
		}
	};
	return (
		<div id="autoenroller">
			<AutoEnrollerForm handleSubmit={handleSubmit} />
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	submitEnroller: () => dispatch(submitEnroller()),
});

export default connect(null, mapDispatchToProps)(AutoEnroller);
