import styled from 'styled-components';
import {Link} from 'gatsby';
import {withUnit, media} from '../../../styles/utils';
import {sizes, colors} from '../../../styles/variables';
import {Icon} from '../../Elements/Icon/Icon';
import Text from '../../Elements/Text/Text';
import Column from '../../Layout/Grid/Column';
import Image from 'components/Elements/Image/Image';

export const LinkStyled = styled(Link)`
	display: block;
	margin-bottom: ${withUnit(sizes.distance.base)};
`;

export const IconBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const IconStyled = styled(Icon)`
	display: block;
	width: 120px;
	height: 100px;
	margin: 0 auto;
	margin-top: 1em;
	color: white;

	svg {
		fill: ${colors.white};
		width: 250px;
		height: 250px;
	}
`;

export const StyledImage = styled(Image)`
	width: 15rem !important;
	margin: 0 auto;
	padding-top: 3rem;

	img {
		margin: 0 auto !important;
	}
`;

export const BGImageStyled = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	z-index: -1;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		${(props) => (props.src ? `background-image: url(${props.src});` : ``)};
		background-size: cover;
		background-position: center center;

		${media.md`
			${(props) => (props.src ? `background-image: url(${props.src});` : ``)};
			background-size: cover;
			background-position: center center; 
		`};
	}
`;

export const TextStyled = styled(Text)`
	text-align: center;
	color: white;
	font-size: 2em;
	z-index: 2;
	margin-top: 0.5em;
	margin-bottom: 0;
`;

export const Wrapper = styled.div``;

export const ColumnStyled = styled(Column)`
	margin-top: 1em;
	margin-bottom: 1em;
`;
