import FilterButton from 'components/Elements/Teaser/FilterButton/FilterButton';
import Text from 'components/Elements/Text/Text';
import styled from 'styled-components';
import {media} from 'styles/utils';
import {colors} from 'styles/variables';

export const UserQueryStyled = styled.div`
	${media.xxl`
		margin-bottom: 5em;
		margin-top: 2.5em;
	`};
`;

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	${media.xxl`
		flex-direction: row;
		align-items: center;
	`};
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 1em 0;
	padding: 0.1em;
	max-width: 100%;
`;

export const StyledFilterButton = styled(FilterButton)`
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
`;

export const QuestionBlock = styled.div`
	opacity: ${({isActive}) => (isActive ? 1 : 0.5)};
	pointer-events: ${({isActive}) => (isActive ? 'all' : 'none')};
	margin-top: 1em;
	transition: 200ms;
`;

export const ErrorBlock = styled.div`
	display: ${({isVisible}) => (isVisible ? 'block' : 'none')};
	background: ${colors.lightgrey};
	border-radius: 1.5em;
	padding: 1em;
	margin-bottom: 1em;

	${media.xxl`
		position: absolute;
		left: 25em;
	`};
`;

export const SuccessBlock = styled.div`
	display: ${({isVisible}) => (isVisible ? 'inline-block' : 'none')};
	background: ${colors.gradient};
	border-radius: 1.5em;
	padding: 1em;
	margin-bottom: 2em;

	a {
		color: white !important;
		text-decoration: underline;
	}
`;

export const Title = styled(Text)`
	color: ${colors.carrot};
	font-weight: bold;
	margin-bottom: 1em;
`;
