import styled, {css} from 'styled-components';
import {withUnit, media, gradientService, gradientCandyCarrot} from '../../../styles/utils';
import {sizes, colors, fonts} from '../../../styles/variables';

import Headline from '../Headline/Headline';
import {Icon} from '../Icon/Icon';

import BgDesktop from '../../../assets/img/therapy/Desktop.jpg';
import BgMobile from '../../../assets/img/therapy/Mobile.jpg';

export const IconInfoboxStyled = styled.div`
	position: relative;
	display: flex;
	padding: ${withUnit(sizes.distance.base / 2)};
	margin-bottom: ${withUnit(sizes.distance.base)};
	align-items: center;

	${media.lg`
		padding: ${withUnit(sizes.distance.base)};
	`};

	${(props) => (props.boxType === 'tiny' ? 'padding: 0; margin: 0;' : '')};
	${(props) =>
		props.boxType === 'tiny' && ((props.size.width <= 800 && props.size.width > 600) || props.size.width < 400)
			? 'font-size: 0.85em;'
			: 'font-size: 1em;'}
	${(props) => (props.size.width <= 600 ? 'flex-direction: column;' : 'flex-direction: row;')}

	${(props) =>
		props.theme === 'service' ? `${gradientService()}` : props.theme !== 'therapy' && `${gradientCandyCarrot()}`};
	${(props) =>
		props.theme === 'therapy' &&
		css`
			&::before {
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-size: cover;
				background-position-y: top;
				background-position-x: right;
				background-image: ${`url(${BgMobile})`};
				z-index: -1;

				${media.md`
					background-image: ${`url(${BgDesktop})`};
					background-repeat: no-repeat;
					background-size: cover;
					background-position-y: top;
					background-position-x: right;
				`};
			}
		`};
`;

export const IconWrapper = styled.div`
	margin: ${withUnit(sizes.distance.base)};
	${(props) => (props.boxType === 'tiny' ? 'padding: 0; margin: 0;' : '')};

	${(props) =>
		props.theme === 'therapy' &&
		css`
			position: absolute;
			right: 0;
			top: 0;
			margin: 0;
			width: 130px;
			height: 130px;

			${media.sm`
			width: 165px;
			height: 165px;
			`};
		`};
`;

export const ContentWrapper = styled.div`
	margin: ${withUnit(sizes.distance.base)};

	${(props) =>
		props.theme === 'therapy' &&
		css`
			padding-top: 150px;

			${media.md`
				padding-top: 0;
				padding-right: 25%;
			`};
		`};
`;

export const HeadlineStyled = styled(Headline)`
	font-family: ${fonts.serif};
	font-weight: 300;
	color: ${colors.white};
	${(props) => (props.align === 'rtl' ? 'text-align: right; direction: rtl' : '')};
	margin-top: 0;
	margin-bottom: ${withUnit(sizes.distance.base / 2)};

	${media.md`
		margin-bottom: ${withUnit(sizes.distance.base / 2)};
	`};
`;

export const IconStyled = styled(Icon)`
	display: block;
	width: 200px;
	height: 200px;
	margin: 0 auto;

	svg {
		fill: ${colors.white};
		width: 200px;
		height: 200px;
	}

	${media.lg`
		width: 140px;
		height: 140px;

		svg {
			width: 140px;
			height: 140px;
		}
	`};

	${media.xl`
		width: 200px;
		height: 200px;

		svg {
			width: 200px;
			height: 200px;
		}
	`};

	${(props) =>
		props.theme === 'therapy' &&
		css`
			width: 100% !important;
			height: 100% !important;

			svg {
				width: 100% !important;
				height: 100% !important;
			}
		`};
`;
