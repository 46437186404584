import styled from 'styled-components';
import IcoEm from '../../../assets/img/icons/ico-em.png';
import {media} from 'styles/utils';

export const EMTeaserStyled = styled.div`
	margin: 1rem 0;
	padding-top: 50px;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
`;

export const StyledTips = styled.div`
	position: relative;
	width: 100%;
	max-width: 750px;
	box-shadow: 4px 4px 15px 0 rgb(255, 74, 74, 0.3);
	border-radius: 14px;
	height: 100%;
`;

export const Gradient = styled.div`
	height: 100%;
	border-radius: 14px;
	padding: 32px;
	background: linear-gradient(90deg, rgba(255, 74, 74, 1) 0%, rgba(255, 133, 74, 1) 50%, rgba(253, 192, 49, 1) 100%);
`;

export const GradientShadow = styled.div`
	width: 100%;
	height: 100%;
	box-shadow: 4px 4px 15px 0 rgb(255, 74, 74, 0.3);
	border-radius: 14px;
	position: absolute;
	top: 0;
	left: 0;
`;

export const CircleShadow = styled.div`
	position: absolute;
	border-radius: 100px;
	box-shadow: 4px 4px 15px 0 rgb(255, 74, 74, 0.3);
	top: -45px;
	right: 55px;
	width: 85px;
	height: 85px;
	z-index: -1;
`;

export const CircleGradient = styled.div`
	position: absolute;
	width: 100%;
	max-width: 750px;
	height: 100px;
	left: 0;
	top: -50px;
	background: linear-gradient(90deg, rgba(255, 74, 74, 1) 0%, rgba(255, 133, 74, 1) 50%, rgba(253, 192, 49, 1) 100%);
	clip-path: ${(props) => `circle(45px at calc(${props.iconPosition === 'left' ? '96px' : ' 100% - 96px'}))`};
`;

export const StyledIcon = styled.div`
	position: absolute;
	width: 56px;
	height: 56px;
	right: ${(props) => (props.iconPosition === 'right' ? '42px' : '')};
	left: ${(props) => (props.iconPosition === 'left' ? '96px' : '')};
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url(${IcoEm});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const StyledButton = styled.button`
	outline: none;
	padding: 0.75rem 1.5rem;
	background: white;
	border-radius: 14px;
	cursor: pointer;
	box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2);
	border: none;
	margin: 0 auto;
`;

export const StyledGirl = styled.img`
	position: absolute;
	top: 64px;
	right: -27px;
	height: 175px !important;

	${media.md`
		top: 45px;
		right: -39px;
		height: 250px !important;
	`};
`;
