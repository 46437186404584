import styled from 'styled-components';
import {colors} from '../../../../styles/variables';
import IconInfobox from '../../../Elements/IconInfobox/IconInfobox';

export const TherapyToolStyled = styled(IconInfobox)``;

export const Wrapper = styled.div`
	margin: 1em 0;
	color: ${colors.white};

	p {
		color: ${colors.white};
	}
`;
