import React from 'react';

import Text from 'components/Elements/Text/Text';
import Girl from '../../../assets/img/img-girl-2x.png';

import {
	ButtonContainer,
	CircleGradient,
	CircleShadow,
	EMTeaserStyled,
	Gradient,
	StyledButton,
	StyledGirl,
	StyledIcon,
	StyledTips,
} from './styles';

const EMTeaser = () => {
	const iconPosition = 'right';

	return (
		<EMTeaserStyled>
			<StyledTips>
				<CircleShadow iconPosition={iconPosition} />
				<CircleGradient iconPosition={iconPosition}>
					<StyledIcon iconPosition={iconPosition} />
				</CircleGradient>
				<Gradient>
					<Text
						styles={{
							color: 'white',
							fontWeight: 'bold',
							fontSize: '1.2em',
							marginBottom: '1em',
							paddingRight: '25%',
						}}
						content="Dein Ernährungs&shy;manager"
					/>
					<Text
						styles={{color: 'white', fontSize: '0.9em', paddingRight: '25%'}}
						content="Lecker und leicht zu einem gesunden Lifestyle! Der ErnährungsManager gehört zu deinen 360°-Services, unterstützt dich als dein digitaler Alltagshelfer und inspiriert mit ausgewogenen Rezepten zum Nachkochen!"
					/>
					<ButtonContainer>
						<StyledButton onClick={() => window.open('https://mein.ms-life.de/nutritionmanager/')}>
							<Text
								styles={{fontWeight: 'bold', margin: '0', fontSize: '1.2em'}}
								content="Jetzt entdecken"
							/>
						</StyledButton>
					</ButtonContainer>
				</Gradient>
				<StyledGirl src={Girl} alt="Ernährungsmanager" />
			</StyledTips>
		</EMTeaserStyled>
	);
};

/** @component */
export default EMTeaser;
