import React from 'react';
import PropTypes from 'prop-types';

const WpAnchorId = (props) => {
	const {className, content} = props;

	return <div id={content.anchor_id} className={className} />;
};

WpAnchorId.propTypes = {
	/** Needed to overwrite styling via styled components. */
	className: PropTypes.string,
	content: PropTypes.shape({
		anchor_id: PropTypes.string.isRequired,
	}).isRequired,
};

WpAnchorId.defaultProps = {
	className: 'ap-anchor-id',
};

/** @component */
export default WpAnchorId;
