import React from 'react';
import diaryIcon from '../../../assets/img/cleo/Tagebuch_Icon.svg';
import diaryImage from '../../../assets/img/cleo/Tagebuch.jpg';

import {Styled} from './styles';

export const CleoCard2 = () => (
	<>
		<Styled.Icon src={diaryIcon} alt="Bild eines Fertigpens" />
		<Styled.Text>Tagebuch:</Styled.Text>
		<Styled.TextBold>Deine Gesundheit im Blick</Styled.TextBold>
		<Styled.Image src={diaryImage} alt="Bild eines Fertigpens" />
		<Styled.Ul>
			<Styled.Li>Persönliche Gesundheitswerte dokumentieren</Styled.Li>
			<Styled.Li>Berichte erstellen und teilen</Styled.Li>
			<Styled.Li>Erinnerungsfunktion (Arzttermine, Medikamente)</Styled.Li>
		</Styled.Ul>
		<Styled.Spacer />
	</>
);
