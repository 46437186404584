import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link/Link';
import TeaserShortener from '../TeaserShortener';
import {
	TeaserStyled,
	TeaserImageStyled,
	ToplineStyled,
	TeaserTitleStyled,
	TeaserContentStyled,
	TeaserTextStyled,
	TeaserImageWrapperStyled,
	appearances,
	themes,
	themeColors,
	DEFAULT
} from './styles';
import Label from '../../Label/Label';
import ImpressionClickTrackerHOC from '../../../Container/ImpressionClickTracker/ImpressionClickTracker';

const FlexTeaser = props => {
	const {item, position} = props;
	const appearance = item.appearance || DEFAULT;
	const imageProps = {type: 'teaser', appearance};
	const teaserProps = {
		theme: themeColors[item.theme],
		appearance
	};
	const itemText = item.text ? item.text.replace(/(<([^>]+)>)/gi, '') : '';
	const itemTitle = item.title ? item.title.replace(/(<([^>]+)>)/gi, '') : '';
	const label = item.label ? <Label text={item.label} /> : null;
	const topline = item.topline ? <ToplineStyled {...teaserProps}>{item.topline}</ToplineStyled> : null;
	const text = item.text ? (
		<TeaserTextStyled {...teaserProps}>
			<TeaserShortener text={itemText} lines="3" />
		</TeaserTextStyled>
	) : null;

	return (
		<Link to={`${item.link.url}`}>
			<ImpressionClickTrackerHOC>
				<TeaserStyled
					data-teaser-type="FlexTeaser"
					data-teaser-label={label}
					data-teaser-title={itemTitle}
					data-teaser-link={item.link.url}
					data-teaser-position={position}
					className="ap-teaser"
					{...props}
					{...teaserProps}>
					<TeaserImageWrapperStyled appearance={appearance}>
						<TeaserImageStyled
							src={item.image.localFile.publicURL}
							alt={item.image.alt_text}
							title={item.image.title}
							{...imageProps}
						/>
					</TeaserImageWrapperStyled>
					<TeaserContentStyled {...teaserProps}>
						{label}
						{topline}
						<TeaserTitleStyled {...teaserProps}>
							<div dangerouslySetInnerHTML={{__html: itemTitle}} />
						</TeaserTitleStyled>
						{text}
					</TeaserContentStyled>
				</TeaserStyled>
			</ImpressionClickTrackerHOC>
		</Link>
	);
};

FlexTeaser.propTypes = {
	position: PropTypes.number,
	item: PropTypes.shape({
		appearance: PropTypes.oneOf(appearances), // we allow '', but replace it with DEFAULT
		theme: PropTypes.oneOf(themes), // we allow '', but replace it with DEFAULT
		label: PropTypes.string,
		topline: PropTypes.string,
		title: PropTypes.string,
		text: PropTypes.string,
		onClick: PropTypes.func,
		link: PropTypes.shape({
			title: PropTypes.string,
			url: PropTypes.string.isRequired,
			target: PropTypes.string
		}),
		image: PropTypes.shape({
			source_url: PropTypes.string,
			alt_text: PropTypes.string,
			title: PropTypes.string,
			localFile: PropTypes.shape({
				publicURL: PropTypes.PropTypes.shape({
					srcSet: PropTypes.string,
					src: PropTypes.string
				}).isRequired
			})
		})
	})
};

FlexTeaser.defaultProps = {
	position: null,
	item: {
		appearance: DEFAULT,
		theme: 'orange',
		label: '',
		topline: '',
		title: '',
		text: '',
		onClick: null,
		link: null,
		image: null
	}
};

/** @component */
export default FlexTeaser;
