import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import Slider from '../../Container/Slider/Slider';
import Card from '../../Elements/Card/Card';
import WpImage from '../WpImage/WpImage';
import Text from '../../Elements/Text/Text';

import {VideoStyled} from './styles';

const WpDynamicSlider = (props) => {
	const {content} = props;

	const imageSlider = useMemo(() => {
		if (content?.images) {
			return (
				<Slider maxShow={2}>
					{content?.images.map((slideImage) => (
						<Card>
							<WpImage content={slideImage} />
						</Card>
					))}
				</Slider>
			);
		}
		return null;
	}, []);

	const videoSlider = useMemo(() => {
		if (content?.videos) {
			const currentUrl = typeof document !== 'undefined' ? document.URL : null;
			const urlParts = currentUrl?.split('#')[1];
			const findObjectIndex = content?.videos
				.map((x) => x.caption)
				.findIndex((c) => c.includes(`id="${urlParts}"`));
			const initialSlide = findObjectIndex > -1 ? findObjectIndex : 0;

			return (
				<Slider maxShow={2} initialSlide={initialSlide}>
					{content?.videos.map((slideVideo) => (
						<>
							<Text content={slideVideo.caption} />
							<Card>
								<VideoStyled content={{videoEmbed: slideVideo.video}} />
							</Card>
						</>
					))}
				</Slider>
			);
		}
		return null;
	}, []);

	return (
		<>
			{imageSlider}
			{videoSlider}
		</>
	);
};

WpDynamicSlider.propTypes = {
	content: PropTypes.shape({
		images: PropTypes.arrayOf(),
		videos: PropTypes.arrayOf(),
	}),
};

WpDynamicSlider.defaultProps = {
	content: null,
};

export default WpDynamicSlider;
