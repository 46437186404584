import styled from 'styled-components';
import Video from '../../Elements/Video/Video';

export const VideoStyled = styled(Video)`
	padding-bottom: 0;
	border-radius: 15px;
	overflow: hidden;

	div {
		width: 100%;
		padding-bottom: 0;
	}
`;
