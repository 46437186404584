import React from 'react';
import {TherapyInformation} from '../TherapyInformation';
import {TherapyToolStyled} from './styles';

export const TherapyTool = ({lang}) => {
	let title = '';
	let direction = 'ltr';
	switch (lang) {
		case 'en':
			title = 'MS Therapies';
			break;
		case 'tr':
			title = 'MS tedavileri';
			break;
		case 'ru':
			title = 'Виды лечения РС';
			break;
		case 'ar':
			title = 'علاج التصلب المتعدد MS';
			direction = 'rtl';
			break;
		default:
			title = 'PZN-Nummer eingeben';
	}

	return (
		<TherapyToolStyled
			className="ap-therapyinformation"
			title={title}
			direction={direction}
			theme="therapy"
			iconName="therapie"
			iconType="teaser">
			<TherapyInformation lang={lang} />
		</TherapyToolStyled>
	);
};
