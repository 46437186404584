import React from 'react';

const EventChat = () => {
	return (
		<div style={{width: '100%', display: 'inline-block', position: 'relative'}}>
			<div id="dummy" style={{marginTop: '115%'}}></div>
			<iframe
				src="https://vimeo.com/event/1011033/chat/"
				frameBorder="0"
				style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}></iframe>
		</div>
	);
};

export default EventChat;
