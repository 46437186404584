import React, {useEffect, useState} from 'react';
import useOnClickOutside from 'use-onclickoutside';
import {AnimatePresence, AnimateSharedLayout} from 'framer-motion';
import parse from 'html-react-parser';
import {format} from 'date-fns';

import {
	Bubble,
	SideBubbleClosed,
	SideBubbleTitleImage,
	SideBubbleText,
	SideBubbleButton,
	SideBubbleContent,
} from './styles';

const variants = {
	rollOut: {x: 0, rotate: 0},
	rollIn: {x: 180, rotate: 90},
};

const SideBubble = ({content}) => {
	const [bubbleOpen, toggleBubble] = useState(false);
	const [showTime, toggleShowTime] = useState(false);

	const {lazy_time, weekdays, sidebubble_titel, title, text, button, button_text} = content;
	const today = format(new Date(), 'EEEE').toLocaleLowerCase();
	// console.log('today', Object.values(weekdays).includes(today));

	useEffect(() => {
		if (Object.values(weekdays).includes(today)) {
			if (lazy_time != 0) {
				setTimeout(() => toggleShowTime(true), lazy_time * 1000);
			} else {
				toggleShowTime(true);
			}
		}
	}, []);

	const ref = React.useRef(null);
	useOnClickOutside(ref, () => bubbleOpen && toggleBubble(!bubbleOpen));

	return (
		<AnimateSharedLayout>
			{showTime && (
				<Bubble
					onClick={() => toggleBubble(!bubbleOpen)}
					open={bubbleOpen}
					ref={ref}
					variants={variants}
					initial={false}
					animate={bubbleOpen ? 'rollOut' : 'rollIn'}
					transition={{ease: 'easeOut', duration: 0.5}}>
					{bubbleOpen ? (
						<AnimatePresence>
							<SideBubbleTitleImage src={title.localFile.childImageSharp.fluid} layoutId="underline" />
							<SideBubbleContent layoutId="underline">
								<SideBubbleText>{parse(text)}</SideBubbleText>
								<SideBubbleButton type="link" to={button.url} text={button_text} theme="white" />
							</SideBubbleContent>
						</AnimatePresence>
					) : (
						<SideBubbleClosed>{sidebubble_titel}</SideBubbleClosed>
					)}
				</Bubble>
			)}
		</AnimateSharedLayout>
	);
};

SideBubble.propTypes = {};

SideBubble.defaultProps = {};

export default SideBubble;
