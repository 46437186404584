import React from 'react';
import PropTypes from 'prop-types';
import {QuoteStyled, IconStyled} from './styles';

const Quote = (props) => {
	const {content, className} = props;
	return (
		<QuoteStyled className={className}>
			<IconStyled name="quote" />
			<div dangerouslySetInnerHTML={{__html: content}} />
		</QuoteStyled>
	);
};

Quote.propTypes = {
	className: PropTypes.string,
	content: PropTypes.string.isRequired,
};

Quote.defaultProps = {
	className: 'ap-quote',
};

/** @component */
export default Quote;
