import React from 'react';
import PropTypes from 'prop-types';
import Headline from '../../Elements/Headline/Headline';

const WpHeadline = props => {
	const {content} = props;
	const {as, text} = content;

	return <Headline as={as} text={text}/>;
};

WpHeadline.propTypes = {
	content: PropTypes.shape({
		as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
		text: PropTypes.string.isRequired
	})
};

WpHeadline.defaultProps = {
	content: null
};

/** @component */
export default WpHeadline;
