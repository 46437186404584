import styled from 'styled-components';
import {withUnit, media, gradientService, gradientCandyCarrotRevert} from '../../../../styles/utils';
import {sizes, colors} from '../../../../styles/variables';
import Text from '../../../Elements/Text/Text';
import {HeadlineStyledH2} from '../../../Elements/Headline/styles';
import {Icon} from '../../../Elements/Icon/Icon';

export const NursesServiceStyled = styled.div`
	${gradientService()};
	padding: ${withUnit(sizes.distance.base)};

	${media.lg`
		display: flex;
		align-items: center;
		padding: ${withUnit(sizes.distance.base)};
	`};
`;

export const IconWrapper = styled.div`
	${media.lg`
		padding-right: ${withUnit(sizes.distance.base)};
	`};
`;

export const ContentWrapper = styled.div``;

export const HeadlineStyled = styled(HeadlineStyledH2)`
	color: ${colors.white};
	margin-top: 0;
	margin-bottom: ${withUnit(sizes.distance.base / 4)};

	${media.md`
		margin-bottom: ${withUnit(sizes.distance.base / 2)};
	`};
`;

export const TextStyled = styled(Text)`
	color: ${colors.white};
	margin-bottom: 0;
`;

export const PhoneNumberStyled = styled.span`
	white-space: nowrap;
`;

export const IconStyled = styled(Icon)`
	display: block;
	width: 250px;
	height: 250px;
	margin: 0 auto;
	${media.lg`
		width: 200px;
		height: 200px;
	`};
	${media.xl`
		width: 250px;
		height: 250px;
	`};
	${media.xxl`
		width: 300px;
		height: 300px;
	`};

	svg {
		fill: ${colors.white};
		width: 250px;
		height: 250px;
		${media.lg`
			width: 200px;
			height: 200px;
		`};
		${media.xl`
			width: 250px;
			height: 250px;
		`};
		${media.xxl`
			width: 300px;
			height: 300px;
		`};
	}
`;

/**
 *
 * Nurse Found
 *
 */

export const NurseStyled = styled.div`
	position: relative;
	overflow: hidden;
	${gradientCandyCarrotRevert()};
	padding: ${withUnit(sizes.distance.base)};
	${media.lg`
		display: flex;
		align-items: center;
		padding: ${withUnit(sizes.distance.base)};
	`};
`;

export const PhotoWrapper = styled.div`
	${media.lg`
		padding-right: ${withUnit(sizes.distance.base)};
	`};
`;

export const IconBackgroundWrapper = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	transform: translate(7%, 7%) scale(2);
	opacity: 0.2;

	svg {
		fill: white;
	}
`;

export const ContactStyled = styled.div`
	margin: ${withUnit(sizes.distance.base)} 0;
	color: ${colors.white};
`;

export const NursePhotoStyled = styled.img`
	display: block;
	border-radius: 50%;
	overflow: hidden;
	width: 250px;
	height: 250px;
	margin: 0 auto ${withUnit(sizes.distance.base)} auto;

	${media.lg`
		margin: 0 auto;
		width: 200px;
		height: 200px;
	`};

	${media.xl`
		width: 250px;
		height: 250px;
	`};

	${media.xxl`
		width: 300px;
		height: 300px;
	`};
`;
