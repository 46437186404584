import React from 'react';
import discoverIcon from '../../../assets/img/cleo/Entdecken_Icon.svg';
import discoverImage from '../../../assets/img/cleo/Entdecken.jpg';

import {Styled} from './styles';

export const CleoCard1 = () => (
	<>
		<Styled.Icon src={discoverIcon} alt="Bild eines Fertigpens" />
		<Styled.Text>Entdecken:</Styled.Text>
		<Styled.TextBold>Personalisierte Inhalte</Styled.TextBold>
		<Styled.Image src={discoverImage} alt="Bild eines Fertigpens" />
		<Styled.Ul>
			<Styled.Li>Medizinisches Grundlagenwissen</Styled.Li>
			<Styled.Li>
				Spannende Alltagsthemen
				<br /> (z. B. Ernährung, Partnerschaft)
			</Styled.Li>
			<Styled.Li>Patientengeschichten</Styled.Li>
		</Styled.Ul>
		<Styled.Spacer />
	</>
);
