import React from 'react';
import cleo from '../../../assets/img/cleo/cleo.png';
import Button from '../../Elements/Button/Button';

import {Styled} from './styles';

export const CleoWeb = () => (
	<>
		<Styled.Block flex>
			<Styled.Image src={cleo} alt="Bild eines Fertigpens" />
			<Styled.InlineText thin>
				Du interessierst dich für noch detailliertere Infos?
				<br />
				<Styled.Bold>Dann schau dir die Website cleo-app.de an.</Styled.Bold>
			</Styled.InlineText>
		</Styled.Block>

		<Button to="https://cleo-app.de/" text="Zur Cleo Website" type="link" size="small" />
		<Styled.Spacer />
	</>
);
