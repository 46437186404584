import React, {useState} from 'react';
import {Datepicker} from 'react-formik-ui';
import de from 'date-fns/locale/de';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';

import * as Yup from 'yup';
import {Formik, Form} from 'formik';

import {
	captchaValidation,
	emailValidation,
	nonEmptyStringValidation,
	dateValidation,
	mustBeTrueValidation,
} from '../../../utils/validations';
import {forms} from '../../../assets/language/de';

import Column from '../../Layout/Grid/Column';
import Headline from '../../Elements/Headline/Headline';
import Row from '../../Layout/Grid/Row';
import Button from '../../Elements/Button/Button';
import {Input, Textarea} from '../../Elements/Form/InputAndTextarea/InputAndTextarea';
import Checkbox from '../../Elements/Form/Checkbox/Checkbox';
import Captcha from '../../Elements/Form/Captcha/Captcha';
import Dropdown from '../../Elements/Form/Dropdown/Dropdown';

import {withAsterisk} from '../../../utils/formHelpers';
import {DatepickerStyled, Spacer} from './styles';
import Text from 'components/Elements/Text/Text';

const {label} = forms.AutoEnrollerForm;

const salutations = ['Frau', 'Herr', 'Divers'].map((label) => ({
	type: label, // because we select "Frau" or "Herr", not an id
	label,
}));

const medications = [
	'Kapsel zur oralen Einnahme (Dimethylfumarat)',
	'Kapsel zur oralen Einnahme (Diroximelfumarat)',
	'Injektion einmal pro Woche (Interferon beta-1a i.m.)',
	'Injektion alle 14 Tage (Peginterferon beta-1a s.c. / i.m.)',
	'Applikation alle 4 Wochen (Natalizumab i.v./s.c.)',
].map((label) => ({
	type: label,
	label,
}));

const {validationMessages} = forms.AutoEnrollerForm;

const AutoEnrollerForm = (props) => {
	const [startTime, setStartTime] = useState(setHours(setMinutes(new Date(), 0), 8));
	const [endTime, setEndTime] = useState(setHours(setMinutes(new Date(), 0), 8));
	const [therapyStartDate, setTherapyStartDate] = useState(new Date());

	const handleSubmit = async (data, {resetForm}) => {
		props.handleSubmit(data);
		resetForm();
	};

	const initialValues = {
		captcha: '',
		email: '',
		forename: '',
		phone: '',
		registerInfo: false,
		messageBox: false,
		salutation: '',
		street: '',
		surname: '',
		zip: '',
		city: '',
		dob: '',
		info: '',
		medication: '',
		therapyStartDate: '',
	};

	const validationSchema = Yup.object().shape({
		captcha: captchaValidation(validationMessages.captcha),
		email: emailValidation(validationMessages.email.ifEmpty, validationMessages.email.ifNotValid),
		forename: nonEmptyStringValidation(validationMessages.forename.ifEmpty),
		registerInfo: mustBeTrueValidation(validationMessages.registerInfo),
		salutation: nonEmptyStringValidation(validationMessages.salutation),
		dob: dateValidation(validationMessages.dob),
		phone: Yup.string()
			.trim()
			.min(8, 'Ungültige Eingabe')
			.required('Bitte gebe deine Telefon- oder Mobilnummer an.'),
		street: nonEmptyStringValidation(validationMessages.street.ifEmpty),
		surname: nonEmptyStringValidation(validationMessages.surname.ifEmpty),
		zip: nonEmptyStringValidation(validationMessages.zip.ifEmpty),
		city: nonEmptyStringValidation(validationMessages.city.ifEmpty),
		medication: nonEmptyStringValidation(validationMessages.medication.ifSelect),
		therapyStartDate: dateValidation(validationMessages.therapyStartDate),
	});

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			validateOnBlur={false}>
			{(props) => (
				<Form mode="structured">
					<Spacer />
					<Headline as="h3" text="Persönliche Angaben" />
					<Spacer />
					<div>
						<Row>
							<Column md={6}>
								<Dropdown
									label={withAsterisk(label.salutation)}
									items={salutations}
									name="salutation"
								/>
							</Column>
						</Row>
						<Row>
							<Column md={6}>
								<Input label={withAsterisk(label.forename)} name="forename" />
							</Column>
							<Column md={6}>
								<Input label={withAsterisk(label.surname)} name="surname" />
							</Column>
						</Row>

						<Row>
							<Column md={6}>
								<DatepickerStyled>
									<Datepicker
										name="dob"
										label={withAsterisk(label.dob)}
										dateFormat="dd.MM.yyyy"
										locale={de}
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										maxDate={new Date()}
									/>
								</DatepickerStyled>
							</Column>
							<Column md={6}>
								<Input label={withAsterisk(label.email)} name="email" />
							</Column>
						</Row>

						<Row>
							<Column md={12}>
								<Input label={withAsterisk(label.street)} name="street" />
							</Column>
						</Row>
						<Row>
							<Column md={6}>
								<Input label={withAsterisk(label.zip)} name="zip" />
							</Column>
							<Column md={6}>
								<Input label={withAsterisk(label.city)} name="city" />
							</Column>
						</Row>
						<Row>
							<Column md={12}>
								<DatepickerStyled>
									<Datepicker
										name="therapyStartDate"
										label={withAsterisk(label.therapyStartDate)}
										locale={de}
										onChange={(date) => setTherapyStartDate(date)}
										selected={therapyStartDate}
										dateFormat="dd.MM.yyyy"
										dropdownMode="select"
										showMonthDropdown
										showYearDropdown
									/>
								</DatepickerStyled>
							</Column>
						</Row>
						<Row>
							<Column>
								<Text
									styles={{margin: '1rem 1.5rem'}}
									content="Für den Fall, dass wir dich telefonisch erreichen wollen, teile uns gerne deine Erreichbarkeiten mit:"
								/>
							</Column>
						</Row>
						<Row>
							<Column md={12}>
								<Input label={withAsterisk(label.phone)} name="phone" />
							</Column>
						</Row>

						<Row>
							<Column md={6}>
								<DatepickerStyled>
									<Datepicker
										name="contactTimeStart"
										label={label.contactTimeStart}
										locale={de}
										selected={startTime}
										onChange={(date) => setStartTime(date)}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										timeCaption="Uhrzeit"
										dateFormat="h:mm aa"
										dropdownMode="select"
										minTime={setHours(setMinutes(new Date(), 0), 8)}
										maxTime={setHours(setMinutes(new Date(), 0), 20)}
									/>
								</DatepickerStyled>
							</Column>
							<Column md={6}>
								<DatepickerStyled>
									<Datepicker
										name="contactTimeEnd"
										label={label.contactTimeEnd}
										locale={de}
										selected={endTime}
										onChange={(date) => setEndTime(date)}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										timeCaption="Uhrzeit"
										dateFormat="h:mm aa"
										dropdownMode="select"
										minTime={new Date(props.values.contactTimeStart)}
										maxTime={setHours(setMinutes(new Date(), 0), 20)}
									/>
								</DatepickerStyled>
							</Column>
						</Row>

						<Row>
							<Column>
								<Checkbox label={label.messageBox} name="messageBox" />
							</Column>
						</Row>
						<Row>
							<Column>
								<Dropdown
									label={withAsterisk(label.medication)}
									items={medications}
									name="medication"
								/>
							</Column>
						</Row>
					</div>
					<div>
						<Row>
							<Column md={12}>
								<Textarea label={label.info} name="info" />
							</Column>
						</Row>
						<Row>
							<Column>
								<Checkbox label={withAsterisk(label.registerInfo)} name="registerInfo" />
							</Column>
						</Row>
						<Row>
							<Column>
								<Captcha name="captcha" />
							</Column>
						</Row>
						<Row>
							<Column>
								<Button
									disabled={props.isSubmitting}
									text="Senden"
									type="submit"
									onClick={(e) => e.stopPropagation()}
									theme="candy"
								/>
							</Column>
						</Row>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default AutoEnrollerForm;
