import React from 'react';
import {Styled} from './styles';
import Headline from '../../Elements/Headline/Headline';
import Button from '../../Elements/Button/Button';
import cleo from '../../../assets/img/cleo/cleo.png';
import apple from '../../../assets/img/cleo/app-store-de-data.png';
import google from '../../../assets/img/cleo/googleplay-de-data.png';
import Link from '../../Elements/Link/Link';

const CleoBlock2 = () => {
	return (
		<Styled.GreyBox>
			<Styled.Row>
				<Styled.Image src={cleo} alt="Bild eines Fertigpens" />
				<Headline as="h2" text="Die App für Multiple Sklerose" style={{margin: '0'}} />
			</Styled.Row>
			<Styled.BreakRow>
				<Button
					type="link"
					to="https://cleo-app-de.onelink.me/Ae8g?pid=download-link&c=Cleo_DE_meinmslife_buttons&af_channel=meinmslife-website&is_retargeting=true&utm_medium=meinmslife-website&utm_source=download-link&utm_campaign=Cleo_DE_meinmslife_buttons&af_dp=cleo%3A%2F%2Fauth%2Fhome%3Futm_medium%3Dmeinmslife-website%26utm_source%3Ddownload-link%26utm_campaign%3DCleo_DE_meinmslife_buttons&af_web_dp=https%3A%2F%2Fwww.cleo-app.de"
					text="Jetzt kostenlos downloaden"
					theme="candy"
					style={{fontSize: '0.9em'}}
				/>
			</Styled.BreakRow>

			<Styled.BreakRow>
				<Link to="https://apps.apple.com/de/app/cleo-meine-ms-app/id1372835752">
					<Styled.StoreImage src={apple} alt="Bild eines Fertigpens" />
				</Link>
				<Link to="https://play.google.com/store/apps/details?id=com.biogen.cleo.de&hl=de&gl=US">
					<Styled.StoreImage src={google} alt="Bild eines Fertigpens" />
				</Link>
			</Styled.BreakRow>
			<Styled.Textblock content="<p>Die App gibt es auf Google Play und im App Store unter <b>Cleo &ndash; Meine MS-App</b></p>" />
		</Styled.GreyBox>
	);
};

export default CleoBlock2;
