import styled from 'styled-components';
import {withUnit} from '../../../styles/utils';
import {sizes} from '../../../styles/variables';

export const SliderWrapper = styled.div`
	position: relative;
	width: 100%;

	.slider,
	.slick-slide {
		padding: 0 !important;
	}

	.slider {
		padding-bottom: ${(props) => (props.singleItem ? '0' : `${withUnit(sizes.distance.base * 1.5)} !important`)};
	}

	.slick-slider {
		margin: 0 !important;
	}
`;

export const SliderItem = styled.div`
	padding-bottom: 0;

	div {
		width: 100%;
		padding: 0;
		margin: 0 !important;
	}
`;
