import React from 'react';
import PropTypes from 'prop-types';
import List from '../../Elements/List/List';

const WpList = ({content}) => {
	return <List as={content.as} items={content.items} />;
};

WpList.propTypes = {
	content: PropTypes.shape({
		as: PropTypes.oneOf(['ul', 'ol']),
		items: PropTypes.array.isRequired
	})
};

WpList.defaultProps = {
	content: {
		as: 'ul'
	}
};

/** @component */
export default WpList;
