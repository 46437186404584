import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import {CaptionStyled, VideoStyled, VideoWrapperStyled} from './styles';

const Video = (props) => {
	const {className, content} = props;

	let afterParse = null;

	if (content.caption) {
		const beforeParse = content.caption;

		const regex1 = /<</gi;
		const regex2 = />>/gi;

		afterParse = beforeParse.replace(regex1, '<div dir="rtl">');
		afterParse = afterParse.replace(regex2, '</div>');
	}

	return (
		<VideoWrapperStyled className={className}>
			<VideoStyled>
				<ReactPlayerLoader
					accountId="5662516403001"
					videoId={content.videoEmbed}
					playerId="0hVpNUYdY"
					embedId="default"
					className="vjs-16-9"
					embedOptions={{
						responsive: true,
					}}
				/>
			</VideoStyled>
			{content.caption && (
				<CaptionStyled>
					<div dangerouslySetInnerHTML={{__html: afterParse}} />
				</CaptionStyled>
			)}
		</VideoWrapperStyled>
	);
};

Video.propTypes = {
	/** Needed to overwrite styling via styled components. */
	className: PropTypes.string,
	content: PropTypes.shape({
		caption: PropTypes.string,
		videoEmbed: PropTypes.string.isRequired,
	}).isRequired,
};

Video.defaultProps = {
	className: 'ap-video',
};

/** @component */
export default Video;
