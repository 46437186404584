import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Elements/Button/Button';

const WpDownloadButton = ({content}) => {
	if (!content.file || !content.file.url || !content.file.url.localFile) {
		return null;
	}
	return (
		<Button
			type="link"
			to={content.file.url.localFile.publicURL}
			direction={content.button_theme}
			text={content.file.title}
		/>
	);
};

WpDownloadButton.propTypes = {
	content: PropTypes.shape({
		button_theme: PropTypes.string,
		icon: PropTypes.string,
		file: PropTypes.shape({
			title: PropTypes.string,
			filename: PropTypes.string,
			url: PropTypes.shape({
				localFile: PropTypes.shape({
					publicURL: PropTypes.string.isRequired,
				}),
			}).isRequired,
		}).isRequired,
	}).isRequired,
};

WpDownloadButton.defaultProps = {};

/** @component */
export default WpDownloadButton;
