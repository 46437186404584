import {connect} from 'react-redux';

import Contact from './Contact';
import {submitContact} from '../../../state/actions/contact';

const mapDispatchToProps = (dispatch) => ({
	onSubmit: (data) => dispatch(submitContact(data)),
});

export default connect(null, mapDispatchToProps)(Contact);
