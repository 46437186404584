import React from 'react';
import PropTypes from 'prop-types';
import Column from '../../Layout/Grid/Column';

const WpColumn = ({children}) => <Column md={12}>{children}</Column>;

WpColumn.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

WpColumn.defaultProps = {
	children: null,
};

/** @component */
export default WpColumn;
