import React from 'react';
import {Styled} from './styles';

const CleoBlock1 = () => (
	<Styled.GradientBox>
		<Styled.CleoLogo />
		<Styled.List>
			<Styled.ListElement>unterstützt dich im Alltag</Styled.ListElement>
			<Styled.ListElement>bietet dir wichtige Infos und Services</Styled.ListElement>
			<Styled.ListElement>ist individuell zusammenstellbar</Styled.ListElement>
			<Styled.ListElement>ist kostenfrei</Styled.ListElement>
		</Styled.List>
	</Styled.GradientBox>
);

export default CleoBlock1;
