import React from 'react';
import PropTypes from 'prop-types';
import Column from '../../Layout/Grid/Column';
import FlexTeaser from '../../Elements/Teaser/FlexTeaser/FlexTeaser';
import Row from '../../Layout/Grid/Row';

const WpTeaserGroup = ({content}) => {
	const {items} = content;
	const gridProps = {
		md: 6
	};
	return (
		<Row styles={{marginBottom: true, justifyContent: 'center'}}>
			{items.map((item, index) => {
				let linkObj = null;
				if (item.download) {
					linkObj =
						item.file && item.file.url.localFile
							? {
									url: item.file.url.localFile.publicURL,
									target: '_self'
							  }
							: null;
				} else {
					linkObj = item.link || null;
				}

				return (
					<Column {...gridProps} key={item.key}>
						<FlexTeaser
							position={index + 1}
							key={item.key}
							item={{
								appearance: 'shadow',
								title: item.title,
								topline: item.topline,
								image: {
									localFile: {
										publicURL:
											item.image && item.image.localFile
												? item.image.localFile.childImageSharp.fluid
												: undefined
									}
								},
								link: linkObj
							}}
						/>
					</Column>
				);
			})}
		</Row>
	);
};

WpTeaserGroup.propTypes = {
	content: PropTypes.shape({
		items: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string,
				title: PropTypes.string,
				topline: PropTypes.string,
				theme: PropTypes.string,
				file: PropTypes.shape({
					url: PropTypes.shape({
						localFile: PropTypes.shape({
							publicURL: PropTypes.string.isRequired
						})
					})
				}),
				image: PropTypes.shape({
					alt_text: PropTypes.string,
					title: PropTypes.string,
					localFile: PropTypes.shape({
						childImageSharp: PropTypes.shape({
							original: PropTypes.shape({
								src: PropTypes.string.isRequired
							})
						})
					})
				})
			})
		)
	})
};

WpTeaserGroup.defaultProps = {
	content: {
		items: null
	}
};

/** @component */
export default WpTeaserGroup;
